<!-- client/src/views/DashboardView.vue -->

<template>
  <div
    id="dashboard"
    :class="{ 'd-flex': true, 'sidebar-open': isSidebarOpen }"
  >
    <!-- Mobile header that displays selected company -->
    <nav id="dashboard-mobile-header" class="navbar navbar-dark d-md-none">
      <div class="container-fluid justify-content-between">
        <span class="mb-0 company-name">
          {{
            selectedCompanyOrFirst
              ? selectedCompanyOrFirst.name
              : $t("dashboard.select_company")
          }}
        </span>
        <button class="navbar-toggler" type="button" @click="toggleSidebar">
          <font-awesome-icon icon="caret-down" />
        </button>
      </div>
    </nav>
    <!-- Dashboard sidebar for switching companies -->
    <div id="dashboard-sidebar">
      <button
        type="button"
        class="btn-close btn-close-white"
        aria-label="Close"
        @click="toggleSidebar"
      ></button>
      <div class="nav-title">{{ $t("dashboard.companies") }}</div>
      <ul class="company-list">
        <li
          v-for="company in accountData.companies"
          :key="company.id"
          :class="{
            'nav-item': true,
            'is-selected-nav': selectedCompanyOrFirst?.id === company?.id,
          }"
        >
          <a class="nav-link" @click="selectCompany(company.id!)" role="button">
            {{ company.name }}
            <label
              v-if="accountData.admin_account_id"
              class="alert alert-subtle-warning text-center admin-extra-info"
            >
              {{ company.id }}
            </label>
          </a>
          <div v-if="getDocumentsReadyToSign(company).length > 0">
            <div
              v-for="signer in getDocumentsReadyToSign(company)[0]
                .document_signers"
              :key="signer.id"
              class="sidebar-call-to-action"
            >
              <a
                :href="signer.redirect_url"
                class="btn btn-subtle-primary btn-sm sidebar-call-to-action-button"
              >
                <font-awesome-icon class="px-1" icon="signature" />
                {{ $t("dashboard.sign_document") }}
              </a>
            </div>
          </div>
          <div v-else>
            <div
              v-for="generator in company.generators.filter(
                (generator) => !generator.has_registered
              )"
              :key="generator.id"
              class="sidebar-call-to-action"
            >
              <div class="sidebar-call-to-action-title">
                EIC: {{ generator.device_eic }}
              </div>
              <a
                :href="`/signup?step=2&generator_id=${generator.id}&company_id=${generator.company_id}`"
                class="btn btn-subtle-primary btn-sm sidebar-call-to-action-button"
              >
                <font-awesome-icon class="px-1" icon="play" />
                {{ $t("dashboard.continue_registration") }}
              </a>
            </div>
          </div>
        </li>
        <button class="btn btn-link" @click="goToRegisterNewDevice">
          <font-awesome-icon icon="plus" />
          {{ $t("dashboard.add_company_or_device") }}
        </button>
      </ul>
      <div
        v-if="accountData.admin_account_id"
        class="alert alert-subtle-warning text-center"
      >
        <div class="form-group">
          <label>{{ $t("dashboard.add_company_access") }}</label>
          <input
            type="number"
            v-model="newCompanyId"
            class="form-control"
            placeholder='$t("dashboard.company_id")'
          />
        </div>
        <button class="btn btn-primary" @click="confirmAddCompany">
          {{ $t("dashboard.add_company") }}
        </button>
      </div>
    </div>
    <div id="dashboard-wrapper" class="pl-sm-3">
      <div id="dashboard-content" class="flex-grow-1 pt-4">
        <company-data-component
          v-if="accountData && accountData.id && selectedCompanyOrFirst"
          :company="selectedCompanyOrFirst"
          :account_id="accountData.id"
          :is_admin="!!accountData.admin_account_id"
          :updateCompany="updateCompany"
          :key="selectedCompanyOrFirst.id"
        />
        <!-- AccountDataDebugComponent for debugging -->
        <account-data-debug-component :accountData="accountData" class="mb-4" />
        <!-- Admin only content -->
        <admin-company-management-component
          v-if="accountData.admin_account_id"
          :accountData="accountData"
          :companyIndex="companyIndexOrFirst"
          @updateAccount="updateAccount"
          @updateCompany="updateCompany"
          :key="companyIndexOrFirst"
        />
        <!-- Admin only content end -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import type { Account, Document } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { fetchAccount } from "@/composables/account/fetchAccount";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CompanyDataComponent from "@/components/dashboard/CompanyDataComponent.vue";
import AccountDataDebugComponent from "@/components/common/AccountDataDebugComponent.vue";
import AdminCompanyManagementComponent from "@/components/dashboard/AdminCompanyManagementComponent.vue";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import type { Company } from "@/types";
import { accountUpdater } from "@/composables/account/accountUpdater";

export default defineComponent({
  name: "DashboardView",
  components: {
    FontAwesomeIcon,
    CompanyDataComponent,
    AccountDataDebugComponent,
    AdminCompanyManagementComponent,
  },
  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const route = useRoute();
    const router = useRouter();
    const isSidebarOpen = ref(false);

    const afterFetchAccount = (accountData: Account) => {
      if (accountData.companies.length === 0 && !accountData.admin_account_id) {
        router.push("/signup");
      }
      checkStatusForAllDocuments();
    };
    const { accountData } = fetchAccount(afterFetchAccount);
    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    const selectCompany = (company_id: number) => {
      const newQuery = {
        ...route.query,
        company_id: company_id,
      };
      router.push({ name: "Dashboard", query: newQuery });
      toggleSidebar();
    };
    const { selectedCompanyOrFirst, companyIndexOrFirst } =
      useCompanyFinder(accountData);

    const goToRegisterNewDevice = () => {
      router.push({ name: "SignUp" });
    };

    const checkStatusForAllDocuments = async () => {
      for (const company of accountData.companies) {
        for (const document of company.documents) {
          if (document.status !== "completed") {
            for (const signer of document.document_signers) {
              if (signer.account_id === accountData.id) {
                await checkStatus(document, document.dokobit_token);
              }
            }
          }
        }
      }
    };

    const checkStatus = async (document: Document, token: string) => {
      try {
        const response = await goSolidApi.get(
          `/api/document-check-status?token=${token}`
        );
        if (response.status === 200 && response.data) {
          const data = response.data;
          if (data.status === "completed") {
            document.status = "completed";
            document.download_url = data.download_url; // Update download URL if available
            // If document contained legal keys have to refresh the page to force reload of the legal keys
            if (document.legal_keys && document.legal_keys.length > 0) {
              location.reload();
            }
          }
        } else {
          throw new Error("Error checking the document status");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    const newCompanyId = ref<number | null>(null);

    const confirmAddCompany = () => {
      if (newCompanyId.value) {
        const confirmed = window.confirm(
          `Are you sure you want to add company with ID ${newCompanyId.value}?`
        );
        if (confirmed) {
          addCompanyToAccount(newCompanyId.value);
        }
      }
    };

    const addCompanyToAccount = async (company_id: number) => {
      try {
        const response = await goSolidApi.post(
          `/api/admin/accounts/${accountData.id}/add-company/${company_id}`,
          {}
        );
        if (response.status === 200) {
          // Handle response
          console.log("Transfer successful", response);
          window.location.reload();
        } else {
          throw new Error("Error transferring assets");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    const getDocumentsReadyToSign = (company: Company): Document[] => {
      return company.documents.filter(
        (document) => document.status !== "completed"
      );
    };

    const updateAccount = (updatedAccount: Account) => {
      Object.assign(accountData, updatedAccount);
    };

    const updateCompany = async (updatedCompany: Company) => {
      accountData.companies[companyIndexOrFirst.value] = updatedCompany;
      const { postAccountData } = accountUpdater(accountData, updateAccount);
      await postAccountData(
        `/api/company/${updatedCompany.id}/update`,
        undefined,
        updatedCompany.id
      );
      return true;
    };

    return {
      updateAccount,
      accountData,
      selectedCompanyOrFirst,
      companyIndexOrFirst,
      goToRegisterNewDevice,
      checkStatus,
      toggleSidebar,
      isSidebarOpen,
      selectCompany,
      updateCompany,
      getDocumentsReadyToSign,
      confirmAddCompany,
      newCompanyId,
    };
  },
});
</script>

<style scoped lang="scss">
#dashboard {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
}

.admin-extra-info {
  padding: 0;
}

#dashboard-sidebar,
#dashboard-content {
  overflow-y: auto;
}

#dashboard-mobile-header {
  background-color: var(--phoenix-gray-800);
  color: var(--phoenix-white);
  padding: 0.5rem 1rem;
  .company-name {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

@media (min-width: 769px) {
  .btn-close {
    display: none;
  }
}

@media (max-width: 768px) {
  #dashboard {
    flex-direction: column;
  }

  #dashboard.sidebar-open #dashboard-sidebar {
    transform: translateX(0);
  }

  #dashboard .navbar-toggler {
    color: white;
    border-width: 0;
  }

  #dashboard #dashboard-sidebar {
    max-width: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
  }

  #dashboard #dashboard-wrapper {
    background-color: var(--phoenix-gray-800);
  }

  #dashboard.sidebar-open #dashboard-sidebar {
    transform: translateX(0);
  }

  .btn-close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
}

#dashboard-sidebar {
  background-color: var(--phoenix-navbar-vertical-bg-color);
  padding: 1rem;
  padding-top: 2rem;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
    background-color: var(--phoenix-gray-700);
    background-clip: padding-box;
    border-radius: 2px;
    border-right: 6px solid transparent;
    border-top: 10px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: white;
    background-clip: padding-box;
    border-radius: 2px;
    border-right: 6px solid transparent;
    border-top: 10px solid transparent;

    //-webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }

  .nav-title {
    text-transform: uppercase;
    color: var(--phoenix-navbar-vertical-label-color);
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .company-list {
    list-style: none;
    padding: 0;
    margin: 0;
    .nav-item {
      font-weight: bold;
      color: var(--phoenix-navbar-vertical-link-color);
      padding: 0.5rem;
      padding-left: 1rem;
      margin-bottom: 1rem;
      &.is-selected-nav {
        color: var(--phoenix-navbar-vertical-link-active-color);
        background-color: var(--phoenix-navbar-vertical-link-hover-bg);
        border-radius: 8px;
      }
      .sidebar-call-to-action {
        margin-left: 1rem;
        .sidebar-call-to-action-button {
          margin-bottom: 0.5rem;
        }
        .sidebar-call-to-action-title {
          font-size: 0.8rem;
          font-weight: normal;
          margin-top: 0.25rem;
          color: var(--phoenix-gray-400);
        }
      }
    }
  }

  .btn-link {
    color: var(--phoenix-navbar-vertical-link-color);
    font-size: 1rem;
    padding: 0.5rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
    &:hover {
      color: var(--phoenix-navbar-vertical-link-active-color);
    }
  }
}

#dashboard-content {
  flex-grow: 1;
  background-color: var(--phoenix-body-bg);
  border-radius: 1rem 1rem 0 0;
}

#dashboard-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  background-color: var(--phoenix-navbar-vertical-bg-color);
}
.container {
  text-align: left;
  max-width: 800px;
}
</style>
@/composables/account/fetchAccount
