// src/composables/fetchAccount.ts

import { computed } from "vue";
import type { Account } from "@/types";
import { useRoute, useRouter } from "vue-router";
import { useCompanyFinder } from "./useCompanyFinder";
import { LANGUAGE_TO_COUNTRY } from "@/constants";

export function useSelectedCountry(accountData: Account) {
  const route = useRoute();
  const router = useRouter();
  const { selectedCompany, companyIdQueryString } =
    useCompanyFinder(accountData);

  const selectedCountry = computed(() => {
    return route.query.country as string;
  });

  const ensureCountryChosen = () => {
    if (!selectedCountry.value) {
      setCountryFromCompanyorLanguage();
    }
  };

  const setCountryFromCompanyorLanguage = async () => {
    const country = getCountryFromCompanyOrLanguage();
    const newQuery = {
      ...route.query,
      country: country,
    };
    await router.push({ name: "SignUp", query: newQuery });
  };

  return {
    ensureCountryChosen,
    selectedCountry,
  };

  function getCountryFromCompanyOrLanguage() {
    // If we already are modifying a company, we should use it's country
    if (companyIdQueryString.value) {
      return selectedCompany.value.country;
    } else {
      // We don't have a company, so we try to get the country from the language
      return LANGUAGE_TO_COUNTRY[
        accountData.language as keyof typeof LANGUAGE_TO_COUNTRY
      ];
    }
  }
}
