<!-- client/src/components/signup/se/AddressReviewComponent.vue -->

<template>
  <div class="generator-review card p-4 mb-3" novalidate>
    <h4>{{ $t("signup.se.generator_review.company_address") }}</h4>
    <DynamicGeneratorField
      v-for="field in generatorFields"
      :key="field.title"
      :field="field"
      v-model="
        localGenerator.application_information.full_company_address[field.model]
      "
      translationPrefix="signup.se"
    />

    <div class="form-check mb-3">
      <input
        type="checkbox"
        class="form-check-input"
        id="sameAsCompanyAddress"
        v-model="sameAsCompanyAddress"
      />
      <label class="form-check-label" for="sameAsCompanyAddress">
        {{ $t("signup.se.generator_review.same_as_company_address") }}
      </label>
    </div>

    <template v-if="!sameAsCompanyAddress">
      <h4>{{ $t("signup.se.generator_review.invoice_address") }}</h4>
      <DynamicGeneratorField
        v-for="field in generatorFields"
        :key="`invoice-${field.title}`"
        :field="field"
        v-model="
          localGenerator.application_information.invoice_address[field.model]
        "
        translationPrefix="signup.se"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref, watch } from "vue";
import type { Account } from "@/types";
import { useI18n } from "vue-i18n";
import DynamicGeneratorField from "@/components/signup/DynamicGeneratorField.vue";
import type { GeneratorFieldConfig } from "@/components/signup/DynamicGeneratorField.vue";
import { useValidators } from "@/composables/useValidators";

export default defineComponent({
  name: "AddressReviewComponent",
  components: {
    DynamicGeneratorField,
  },
  props: {
    generatorIndex: {
      type: Number,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    accountData: {
      type: Object as () => Account,
      required: true,
    },
  },
  emits: ["handleUpdateGenerator"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const validators = useValidators(t);

    const generator = computed(() => {
      return props.accountData.companies[props.companyIndex].generators[
        props.generatorIndex
      ];
    });

    // Determine initial checkbox state based on whether invoice_address exists
    const sameAsCompanyAddress = ref(
      !generator.value.application_information?.invoice_address
    );

    // Initialize with empty address objects if they don't exist
    const localGenerator = reactive({
      ...generator.value,
      application_information: {
        ...generator.value.application_information,
        full_company_address: {
          postal_address: "",
          postal_code: "",
          city: "",
          ...generator.value.application_information?.full_company_address,
        },
        // Only initialize invoice_address if it exists in generator.value
        invoice_address:
          generator.value.application_information?.invoice_address || null,
      },
    });

    const generatorFields: GeneratorFieldConfig[] = [
      {
        title: "postal_address",
        model: "postal_address",
        type: "string",
        validation: [validators.required],
      },
      {
        title: "postal_code",
        model: "postal_code",
        type: "string",
        validation: [validators.required],
      },
      {
        title: "city",
        model: "city",
        type: "string",
        validation: [validators.required],
      },
    ];

    watch(
      [localGenerator, sameAsCompanyAddress],
      ([newValue, isSameAddress]) => {
        if (isSameAddress) {
          // Set invoice_address to null when addresses are the same
          localGenerator.application_information.invoice_address = null;
        } else if (!localGenerator.application_information.invoice_address) {
          // Initialize invoice_address if it's null and addresses are different
          localGenerator.application_information.invoice_address = {
            postal_address: "",
            postal_code: "",
            city: "",
          };
        }
        emit("handleUpdateGenerator", newValue);
      },
      { deep: true }
    );

    return {
      localGenerator,
      generatorFields,
      sameAsCompanyAddress,
    };
  },
});
</script>

<style scoped>
.generator-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
    padding: 0;
  }
  .btn-outline-secondary {
    padding: 0.375rem 0.75rem;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .generator-review {
    .col-form-label {
      margin-top: 1rem;
    }
  }
  #form-title {
    margin-top: 0.2rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  .btn-subtle-danger {
    width: 100%;
  }
}
</style>
