import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f71d5ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "generator-review card p-4 mb-3",
  novalidate: ""
}
const _hoisted_2 = { class: "mb-1 mb-sm-4" }
const _hoisted_3 = { class: "row mb-2 g-2" }
const _hoisted_4 = {
  for: "has-subsidy",
  class: "col-sm-12 pb-0 col-form-label"
}
const _hoisted_5 = { class: "col-sm-11" }
const _hoisted_6 = {
  class: "form-check",
  role: "button"
}
const _hoisted_7 = {
  class: "form-check-label",
  for: "has-subsidy-no",
  role: "button"
}
const _hoisted_8 = {
  class: "form-check",
  role: "button"
}
const _hoisted_9 = {
  class: "form-check-label",
  for: "has-subsidy-yes",
  role: "button"
}
const _hoisted_10 = {
  key: 0,
  class: "row mb-2 gy-3 gx-0 mt-1"
}
const _hoisted_11 = { class: "col-form-label mb-0 pb-0 mt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicGeneratorField = _resolveComponent("DynamicGeneratorField")!
  const _component_SubsidyCardComponent = _resolveComponent("SubsidyCardComponent")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("signup.add_generator_component.title")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generatorFields, (field) => {
      return (_openBlock(), _createBlock(_component_DynamicGeneratorField, {
        key: field.title,
        field: field,
        modelValue: _ctx.localGenerator[field.model],
        "onUpdate:modelValue": ($event: any) => ((_ctx.localGenerator[field.model]) = $event)
      }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("signup.add_generator_component.subsidy_question")), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input pe-auto",
            role: "button",
            type: "radio",
            name: "subsidy_radio",
            id: "has-subsidy-no",
            value: false,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasSubsidy) = $event))
          }, null, 512), [
            [_vModelRadio, _ctx.hasSubsidy]
          ]),
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("common.no")), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            role: "button",
            type: "radio",
            name: "subsidy_radio",
            id: "has-subsidy-yes",
            value: true,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hasSubsidy) = $event))
          }, null, 512), [
            [_vModelRadio, _ctx.hasSubsidy]
          ]),
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("common.yes")), 1)
        ])
      ])
    ]),
    (_ctx.hasSubsidy)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("signup.add_generator_component.subsidy_title")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localGenerator.subsidies, (_subsidy, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_SubsidyCardComponent, {
                subsidy: _ctx.localGenerator.subsidies[index],
                "onUpdate:subsidy": ($event: any) => ((_ctx.localGenerator.subsidies[index]) = $event),
                extraData: false,
                removeSubsidy: () => _ctx.removeSubsidy(index)
              }, null, 8, ["subsidy", "onUpdate:subsidy", "removeSubsidy"])
            ]))
          }), 128)),
          _createElementVNode("button", {
            class: "btn btn-subtle-primary col-sm-11",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addSubsidy && _ctx.addSubsidy(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "plus" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("signup.add_generator_component.add_subsidy")), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}