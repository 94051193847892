<template>
  <div class="settings-component">
    <h4 class="mb-4">{{ $t("settings.title") }}</h4>

    <form @submit.prevent="saveCompanyData">
      <div class="mb-3">
        <label for="vat" class="form-label">{{ $t("company.vat") }}</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': !isVatValid }"
          id="vat"
          v-model="companyData.vat"
          @input="validateVat"
        />
        <div class="invalid-feedback" v-if="!isVatValid">
          {{ $t("company.invalid_vat") }}
        </div>
      </div>
      <div class="mb-3">
        <label for="bank_account" class="form-label">{{
          $t("company.bank_account")
        }}</label>
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': !isIbanValid }"
          id="bank_account"
          v-model="companyData.bank_account"
          @input="validateIbanFormat"
          @blur="validateIban"
          :disabled="isValidating"
        />
        <div class="invalid-feedback" v-if="!isIbanValid">
          {{ $t("validations.iban") }}
        </div>
        <div class="form-text" v-if="isValidating">
          {{ $t("common.validating") }}...
        </div>
      </div>
      <div class="mb-3">
        <label for="bank_name" class="form-label">{{
          $t("company.bank_name")
        }}</label>
        <input
          type="text"
          class="form-control"
          id="bank_name"
          v-model="companyData.bank_name"
          disabled
        />
      </div>
      <div class="mb-3">
        <label for="bank_swift" class="form-label">{{
          $t("company.bank_swift")
        }}</label>
        <input
          type="text"
          class="form-control"
          id="bank_swift"
          v-model="companyData.bank_swift"
          disabled
        />
      </div>
      <button type="submit" class="btn btn-primary" :disabled="!isFormValid">
        <span v-if="isSaving">{{ $t("common.saving") }}...</span>
        <span v-else>{{ $t("common.save") }}</span>
      </button>
    </form>
    <br />
    <TeamComponent :company_id="company_id" :company="company" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  PropType,
  inject,
  computed,
} from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useI18n } from "vue-i18n";
import { Company } from "@/types";
import TeamComponent from "./TeamComponent.vue";
import { isValidIban, isValidVat } from "@/composables/useValidators";

export default defineComponent({
  name: "SettingsComponent",
  props: {
    company_id: {
      type: Number,
      required: true,
    },
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    updateCompany: {
      type: Function as PropType<(company: Company) => Promise<boolean>>,
      required: true,
    },
  },
  components: {
    TeamComponent,
  },
  setup(props) {
    const { t } = useI18n();
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;

    const handleSuccess = inject("globalSuccessHandler") as (
      message: string,
      title: string
    ) => void;

    const companyData = ref({
      vat: props.company.vat || "",
      bank_account: props.company.bank_account || "",
      bank_name: props.company.bank_name || "",
      bank_swift: props.company.bank_swift || "",
    });

    const isVatValid = ref(true);
    const isIbanValid = ref(true);
    const isSaving = ref(false);
    const isValidating = ref(false);

    const isFormValid = computed(() => {
      return (
        isVatValid.value &&
        isIbanValid.value &&
        !isValidating.value &&
        !isSaving.value
      );
    });

    const validateVat = () => {
      isVatValid.value = isValidVat(companyData.value.vat);
    };

    const validateIbanFormat = () => {
      isIbanValid.value = isValidIban(companyData.value.bank_account);
    };

    const validateIban = async () => {
      if (!isIbanValid.value) return;

      if (companyData.value.bank_account) {
        isValidating.value = true;
        try {
          const response = await goSolidApi.get(
            `/api/utils/iban-validator/${companyData.value.bank_account}`
          );
          if (
            response &&
            response.status === 200 &&
            response.data &&
            response.data.valid
          ) {
            companyData.value.bank_name = response.data.bank_name;
            companyData.value.bank_swift = response.data.swift_code;
          } else {
            isIbanValid.value = false;
          }
        } catch (error) {
          globalErrorHandler(error);
          isIbanValid.value = false;
        } finally {
          isValidating.value = false;
        }
      }
    };

    const saveCompanyData = async () => {
      if (!isFormValid.value) return;

      isSaving.value = true;
      try {
        const updatedCompany = {
          ...props.company,
          vat: companyData.value.vat,
          bank_account: companyData.value.bank_account,
          bank_name: companyData.value.bank_name,
          bank_swift: companyData.value.bank_swift,
        };
        const success = await props.updateCompany(updatedCompany);
        if (success) {
          handleSuccess(t("common.saved"), " ");
        }
      } catch (error) {
        globalErrorHandler(error);
      } finally {
        isSaving.value = false;
      }
    };

    onMounted(() => {
      companyData.value = {
        vat: props.company.vat || "",
        bank_account: props.company.bank_account || "",
        bank_name: props.company.bank_name || "",
        bank_swift: props.company.bank_swift || "",
      };
      validateVat();
      validateIbanFormat();
    });

    return {
      companyData,
      validateIban,
      validateVat,
      isVatValid,
      isIbanValid,
      validateIbanFormat,
      isFormValid,
      isValidating,
      isSaving,
      saveCompanyData,
    };
  },
});
</script>
