// client/src/components/signup/se/generator-fields.ts

import { Composer } from "vue-i18n";
import { useValidators } from "@/composables/useValidators";
import type { GeneratorFieldConfig } from "@/components/signup/DynamicGeneratorField.vue";
import { computed } from "vue";

export enum SeGeneratorType {
  WINDLAND = "BTN_Land based",
  WINDOCEAN = "BTN_Ocean based",
  SOLAR = "BTN_Sun",
  HYDRO = "BTN_Water",
}

export enum MeteringLocation {
  DIRECTLY_AFTER_GENERATOR = "Directly_after_generator",
  AFTER_AUXILIARY_EQUIPMENT = "After_auxiliary_equipment",
  AFTER_NET_PUMPING_EQUIPMENT = "After_net_pumping_equipment",
  DIRECTLY_BEFORE_GRID_CONNECTION = "Directly_before_grid_connection",
}

export enum RegistrationStatus {
  REGISTERED = "Registered_with_the_S_E_A",
  NOT_REGISTERED = "Not_registered_with_the_S_E_A",
}

export enum CorrectionFactor {
  STANDARD_VALUE = "Standard_value",
  CUSTOM_VALUE = "Custom_value",
}

export enum InvestmentSupport {
  YES = "Yes",
  NO = "No",
  DONT_KNOW = "Do not know",
}

// Helper function to convert enum to options array
export const enumToOptions = (enumObj: { [key: string]: string }) => {
  return Object.values(enumObj).map((value) => ({
    value,
    label: value,
  }));
};

export function getGoBasicFields(
  t: Composer["t"],
  go_application: any
): GeneratorFieldConfig[] {
  const { required, requiredMinMax } = useValidators(t);

  return [
    {
      title: "grid_area_id",
      model: "grid_area_id",
      type: "string",
      validation: [required],
    },
    {
      title: "grid_production_device_id",
      model: "grid_production_device_id",
      type: "string",
      validation: [required],
    },
    {
      title: "grid_eidel_id",
      model: "grid_eidel_id",
      type: "string",
      validation: [required],
    },
    {
      title: "grid_metered_by_hour",
      model: "grid_metered_by_hour",
      type: "boolean",
    },
    {
      title: "grid_concession",
      model: "grid_concession",
      type: "boolean",
    },
    {
      title: "metering_location",
      model: "metering_location",
      type: "dropdown",
      options: enumToOptions(MeteringLocation),
      validation: [required],
    },
    {
      title: "correction_factor_info",
      model: "correction_factor_info",
      type: "none",
      options: enumToOptions(CorrectionFactor),
      visible: computed(() => {
        return (
          go_application.metering_location !=
          MeteringLocation.DIRECTLY_BEFORE_GRID_CONNECTION
        );
      }),
      validation: [required],
    },
    {
      title: "registration_status",
      model: "registration_status",
      type: "dropdown",
      options: enumToOptions(RegistrationStatus),
      validation: [required],
    },

    {
      title: "holding_percentage",
      model: "holding_percentage",
      type: "number",
      validation: [requiredMinMax(0, 100)],
    },
  ];
}

export function getGeneratorFields(t: Composer["t"]) {
  const { required, requiredMin, requiredMinMax } = useValidators(t);

  const productionUnitFields: GeneratorFieldConfig[] = [
    {
      title: "technology",
      model: "technology",
      type: "dropdown",
      options: enumToOptions(SeGeneratorType),
      validation: [required],
    },
    {
      title: "device_name",
      model: "device_name",
      type: "string",
      validation: [required],
    },
    {
      title: "estimated_annual_production",
      model: "estimated_annual_production",
      type: "number",
      validation: [requiredMin(0)],
    },
    {
      title: "property_designation",
      model: "property_designation",
      type: "string",
      validation: [required],
    },
    {
      title: "postal_code",
      model: "postal_code",
      type: "string",
      validation: [required],
    },
    {
      title: "city",
      model: "city",
      type: "string",
      validation: [required],
    },
  ];

  const investmentFields: GeneratorFieldConfig[] = [
    {
      title: "investment_support_amount",
      model: "investment_support_amount",
      type: "number",
      validation: [requiredMin(0)],
    },
    {
      title: "investment_support_date",
      model: "investment_support_date",
      type: "date",
      validation: [required],
    },
  ];

  const investmentSupportOptions = Object.values(InvestmentSupport).map(
    (value) => ({
      value,
      label: `signup.se.generator_review.investment_support_options.${value
        .toLowerCase()
        .replace(/ /g, "_")}`,
    })
  );

  return {
    productionUnitFields,
    investmentFields,
    investmentSupportOptions,
  };
}
