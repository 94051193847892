<template>
  <div id="AdminRoutes" class="container h-100 overflow-auto py-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6">
        <h1 class="mb-4">Admin Routes</h1>

        <div class="list-group">
          <router-link
            v-for="route in adminRoutes"
            :key="route.path"
            :to="route.path"
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
          >
            <div>
              <h5 class="mb-1">{{ formatRouteName(route.name) }}</h5>
              <small class="text-muted">{{ route.path }}</small>
            </div>
            <span
              v-if="route.meta?.requiresRole"
              class="badge bg-primary rounded-pill fs-7"
            >
              {{ route.meta.requiresRole }}
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import router from "@/router";

export default defineComponent({
  name: "AdminIndex",
  setup() {
    // Get all routes and filter for admin routes
    const adminRoutes = computed(() => {
      let adminRoutes = router.getRoutes().filter((route) => {
        return route.path.startsWith("/admin") && route.name;
      });
      adminRoutes = adminRoutes.sort((a, b) => {
        return a.path.localeCompare(b.path);
      });
      return adminRoutes;
    });

    // Format route name for display
    const formatRouteName = (name: string | symbol | undefined) => {
      if (!name) return "Unnamed Route";
      const nameStr = name.toString();
      return nameStr
        .replace("Admin", "")
        .split(/(?=[A-Z])/)
        .join(" ")
        .trim();
    };

    return {
      adminRoutes,
      formatRouteName,
    };
  },
});
</script>
