import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ebaf5cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "generator-review card p-4 mb-3",
  novalidate: ""
}
const _hoisted_2 = { class: "form-check mb-3" }
const _hoisted_3 = {
  class: "form-check-label",
  for: "sameAsCompanyAddress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicGeneratorField = _resolveComponent("DynamicGeneratorField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.$t("signup.se.generator_review.company_address")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generatorFields, (field) => {
      return (_openBlock(), _createBlock(_component_DynamicGeneratorField, {
        key: field.title,
        field: field,
        modelValue: 
        _ctx.localGenerator.application_information.full_company_address[field.model]
      ,
        "onUpdate:modelValue": ($event: any) => ((
        _ctx.localGenerator.application_information.full_company_address[field.model]
      ) = $event),
        translationPrefix: "signup.se"
      }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
    }), 128)),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        class: "form-check-input",
        id: "sameAsCompanyAddress",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sameAsCompanyAddress) = $event))
      }, null, 512), [
        [_vModelCheckbox, _ctx.sameAsCompanyAddress]
      ]),
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("signup.se.generator_review.same_as_company_address")), 1)
    ]),
    (!_ctx.sameAsCompanyAddress)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t("signup.se.generator_review.invoice_address")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generatorFields, (field) => {
            return (_openBlock(), _createBlock(_component_DynamicGeneratorField, {
              key: `invoice-${field.title}`,
              field: field,
              modelValue: 
          _ctx.localGenerator.application_information.invoice_address[field.model]
        ,
              "onUpdate:modelValue": ($event: any) => ((
          _ctx.localGenerator.application_information.invoice_address[field.model]
        ) = $event),
              translationPrefix: "signup.se"
            }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
          }), 128))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}