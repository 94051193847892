import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d45d529"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "generator-review card p-4 mb-3",
  novalidate: ""
}
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center mb-3" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_5 = { class: "mt-4" }
const _hoisted_6 = { class: "mt-4" }
const _hoisted_7 = { class: "row mb-2 g-2" }
const _hoisted_8 = { class: "col-sm-12 pb-0 col-form-label" }
const _hoisted_9 = { class: "col-sm-11" }
const _hoisted_10 = ["id", "value"]
const _hoisted_11 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicGeneratorField = _resolveComponent("DynamicGeneratorField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.$t("signup.se.generator_review.basic_information")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localGenerator.application_information
        .new_production_unit_applications, (unit, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "border rounded p-3 mb-3"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t("signup.se.generator_review.production_unit")) + " #" + _toDisplayString(index + 1), 1),
          (index === 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: ($event: any) => (_ctx.removeProductionUnit(index)),
                class: "btn btn-outline-danger btn-sm"
              }, _toDisplayString(_ctx.$t("common.remove")), 9, _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productionUnitFields, (field) => {
          return (_openBlock(), _createBlock(_component_DynamicGeneratorField, {
            key: field.title,
            field: field,
            modelValue: unit[field.model],
            "onUpdate:modelValue": ($event: any) => ((unit[field.model]) = $event),
            translationPrefix: "signup.se"
          }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
        }), 128))
      ]))
    }), 128)),
    (_ctx.canAddMoreUnits)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addProductionUnit && _ctx.addProductionUnit(...args))),
            class: "btn btn-outline-primary"
          }, _toDisplayString(_ctx.$t("common.add_another_unit")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t("signup.se.generator_review.metering_information")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goBasicFields, (field) => {
      return (_openBlock(), _createBlock(_component_DynamicGeneratorField, {
        key: field.title,
        field: field,
        modelValue: 
        _ctx.localGenerator.application_information.go_application[field.model]
      ,
        "onUpdate:modelValue": ($event: any) => ((
        _ctx.localGenerator.application_information.go_application[field.model]
      ) = $event),
        translationPrefix: "signup.se"
      }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
    }), 128)),
    _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.$t("signup.se.generator_review.investment_support")), 1),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("signup.se.generator_review.investment_support_question")), 1),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.investmentSupportOptions, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "form-check",
            key: option.value
          }, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              type: "radio",
              id: `investment-support-${option.value}`,
              value: option.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((
              _ctx.localGenerator.application_information.go_application
                .investment_support
            ) = $event))
            }, null, 8, _hoisted_10), [
              [_vModelRadio, 
              _ctx.localGenerator.application_information.go_application
                .investment_support
            ]
            ]),
            _createElementVNode("label", {
              class: "form-check-label",
              for: `investment-support-${option.value}`
            }, _toDisplayString(_ctx.$t(option.label)), 9, _hoisted_11)
          ]))
        }), 128))
      ])
    ]),
    (
        _ctx.localGenerator.application_information.go_application
          .investment_support === 'Yes'
      )
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.investmentFields, (field) => {
          return (_openBlock(), _createBlock(_component_DynamicGeneratorField, {
            key: field.title,
            field: field,
            modelValue: 
          _ctx.localGenerator.application_information.go_application[field.model]
        ,
            "onUpdate:modelValue": ($event: any) => ((
          _ctx.localGenerator.application_information.go_application[field.model]
        ) = $event),
            translationPrefix: "signup.se"
          }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}