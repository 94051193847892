import numeral from "numeral";

function formatNumberWithSpaces(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

if (typeof numeral !== "undefined") {
  // Register a custom format
  numeral.register("format", "spaceSeparated", {
    regexps: {
      format: /(,)/,
      unformat: /\s/g,
    },
    format: function (value, format, roundingFunction) {
      return formatNumberWithSpaces(value);
    },
    unformat: function (string) {
      return parseFloat(string.replace(/\s/g, ""));
    },
  });
}

export function formatDate(dateString: string | null | undefined) {
  if (!dateString) {
    return ""; // Return a default text if dateString is null or undefined
  }

  const [year, month, day] = dateString.split("-");
  if (!day) {
    return `${month}/${year}`;
  }
  return `${day}/${month}/${year}`;
}

export function formatDateTime(dateString: string | null | undefined | Date) {
  if (!dateString) {
    return ""; // Return a default text if dateString is null or undefined
  }
  // Parse the date string into a Date object if it is not already a Date object
  const date = dateString instanceof Date ? dateString : new Date(dateString);

  // Extract the day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as DD/MM/YYYY
  return `${day}/${month}/${year}`;
}

export function formatMoney(value: number) {
  if (value === null || value === undefined) {
    return "n/a";
  }
  // First round the number to 2 decimal places to avoid floating point precision issues
  const roundedValue = Number(Number(value).toFixed(2));
  // Remove the console.logs and use the correct format
  return numeral(roundedValue).format("0,0.00");
}

export function formatLargeNumber(value: number | null | undefined) {
  if (value === null || value === undefined) {
    return "n/a"; // Return a default text if value is null or undefined
  }
  return numeral(value).format("0,0");
}
