// client/src/main.ts

import { createApp, App, Plugin } from "vue";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "./assets/theme.css";
import MainComponent from "./App.vue";
import router from "./router";
import store from "./store";
import { createAuth0 } from "@auth0/auth0-vue";
import metaManager from "./plugins/vue-meta";
import { createI18n } from "vue-i18n";
import et_i18n from "./assets/i18n/et.json";
import en_i18n from "./assets/i18n/en.json";
import lv_i18n from "./assets/i18n/lv.json";
import lt_i18n from "./assets/i18n/lt.json";

// Import FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { all } from "@awesome.me/kit-00d73b0c5d/icons";
import vueCountryRegionSelect from "vue3-country-region-select";
import { isWebview } from "./composables/isWebview";
import logger from "./logger";
// Internationalization and localization
const messages = {
  et: et_i18n,
  en: en_i18n,
  lv: lv_i18n,
  lt: lt_i18n,
};

// Get browser language
const browserLanguage = navigator.language.split("-")[0];
const defaultLocale = Object.keys(messages).includes(browserLanguage)
  ? browserLanguage
  : "en";

// Check for 'language' query parameter
const urlParams = new URLSearchParams(window.location.search);
const queryLanguage = urlParams.get("language");
const initialLocale =
  queryLanguage && Object.keys(messages).includes(queryLanguage)
    ? queryLanguage
    : defaultLocale;

const i18n = createI18n({
  locale: initialLocale,
  messages,
  fallbackLocale: "en",
  legacy: false,
});

// Add all solid icons to the library
// You can also import specific icons if preferred
library.add(...all);

const app: App<Element> = createApp(MainComponent);

// Register FontAwesomeIcon component globally
app.component("FontAwesomeIcon", FontAwesomeIcon);

app.use(store);
app.use(router);
app.use(metaManager);
app.use(vueCountryRegionSelect);

let auth_domain = "";
let auth_client_id = "";

// Set auth0 domain and client_id based on envronment and webview
// Set up logging in production
// app.config.globalProperties.$logtail = logtail; // Make logtail available globally

if (
  process.env.NODE_ENV === "production" &&
  window.location.hostname === "app.soldera.org"
) {
  auth_domain = "soldera-production.eu.auth0.com";
  if (isWebview()) {
    auth_client_id = "2eiVFqjKdrY9nQGhKAXBTLrtpY8aJO9t";
  } else {
    auth_client_id = "kiF6iSU49qhtaMRQgGnondazw7gpwnib";
  }
} else {
  auth_domain = "gosolid-staging.eu.auth0.com";
  if (isWebview()) {
    auth_client_id = "70Te98oFV2bLEJiP0w2odh6FhqPTTVKq";
  } else {
    auth_client_id = "Ren6AWSYBiIUSL8K75VAI0eN4sxq5j27";
  }
}

const auth0 = createAuth0({
  domain: auth_domain,
  clientId: auth_client_id,
  cacheLocation: "localstorage",
  authorizationParams: {
    redirect_uri: window.location.origin + "/callback",
    audience: "Flask",
    ui_locales: initialLocale,
  },
});

app.provide("auth0", auth0);
app.use(auth0 as unknown as Plugin);
app.use(i18n);
app.config.errorHandler = (err: any, instance, info) => {
  const componentInfo = instance
    ? `in ${instance.$options.name || "anonymous component"}`
    : "";
  const errorMessage = `Error ${componentInfo}: ${err.toString()}\n${info}`;
  logger.error(errorMessage);
};

app.mount("#app");

window.onerror = (message, source, lineno, colno, error) => {
  logger.error("Global JavaScript error", {
    message,
    source,
    lineno,
    colno,
    error,
  });
};

window.onunhandledrejection = (event) => {
  logger.error("Unhandled promise rejection", { reason: event.reason });
};
