<!--  client/src/components/signup/lv/DynamicGeneratorField.vue-->
<template>
  <div class="field-container" v-if="isVisible">
    <label :for="field.title" class="field-label">
      {{ $t(`${translationPrefix}.generator_review.${field.title}.title`) }}
    </label>

    <div class="field-description" v-if="hasDescription">
      {{
        $t(`${translationPrefix}.generator_review.${field.title}.description`)
      }}
    </div>

    <!-- String/Number Input -->
    <input
      v-if="['string', 'number'].includes(field.type)"
      :type="field.type === 'number' ? 'number' : 'text'"
      :id="field.title"
      v-model="localValue"
      class="form-control"
      :class="{ 'is-invalid': v$.localValue.$error }"
      @input="handleInput($event)"
      @blur="v$.localValue.$touch"
    />

    <!-- Date Input -->
    <input
      v-else-if="field.type === 'date'"
      type="date"
      :id="field.title"
      v-model="localValue"
      class="form-control"
      :class="{ 'is-invalid': v$.localValue.$error }"
      @input="handleInput($event)"
      @blur="v$.localValue.$touch"
    />

    <!-- Radio Group 
    <div v-else-if="field.type === 'radio'" class="radio-group">
      <div
      v-for="option in field.options"
      :key="option.value"
      class="form-check"
      >
        <input
          type="radio"
          :id="`${field.title}-${option.value}`"
          :name="field.title"
          :value="option.value"
          :checked="modelValue === option.value"
          class="form-check-input"
          :class="{ 'is-invalid': v$.localValue.$error }"
          @change="handleInput($event)"
          @blur="v$.localValue.$touch"
        />
        <label :for="`${field.title}-${option.value}`" class="form-check-label">
          {{ $t(`generator_review.${field.title}.options.${option.label}`) }}
        </label>
      </div>
    </div>
    -->

    <!-- Dropdown -->
    <select
      v-else-if="field.type === 'dropdown'"
      :id="field.title"
      v-model="localValue"
      class="form-select"
      :class="{ 'is-invalid': v$.localValue.$error }"
      @change="handleInput($event)"
      @blur="v$.localValue.$touch"
    >
      <option
        v-for="option in field.options"
        :key="option.label"
        :value="option.value"
      >
        {{
          $t(
            `${translationPrefix}.generator_review.${field.title}.options.${option.label}`
          )
        }}
      </option>
    </select>

    <!-- Boolean Select -->
    <select
      v-else-if="field.type === 'boolean'"
      :id="field.title"
      v-model="localValue"
      class="form-select"
      :class="{ 'is-invalid': v$.localValue.$error }"
      @change="handleInput($event)"
      @blur="v$.localValue.$touch"
    >
      <option :value="true">{{ $t("common.yes") }}</option>
      <option :value="false">{{ $t("common.no") }}</option>
    </select>

    <!-- Validation Error -->

    <div v-if="hasError" class="invalid-feedback">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref, Ref } from "vue";
// import type { GeneratorFieldConfig } from "@/components/signup/lv/generator-fields";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";

export interface GeneratorFieldConfig {
  title: string;
  model: string;
  type:
    | "string"
    | "number"
    | "boolean"
    | "date"
    | "dropdown"
    | "radio"
    | "none";
  options?: { value: string | number | boolean; label: string }[];
  validation?: [any]; //FIXME: any
  editable?: boolean;
  visible?: Ref<boolean>;
  condition?: (data: any) => boolean;
}

export default defineComponent({
  name: "DynamicGeneratorField",
  props: {
    field: {
      type: Object as PropType<GeneratorFieldConfig>,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    translationPrefix: {
      type: String,
      default: "signup.lv",
    },
  },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const { te } = useI18n();
    const hasDescription = computed(() => {
      return te(
        `${props.translationPrefix}.generator_review.${props.field.title}.description`
      );
    });
    const isVisible =
      props.field.visible === undefined ? true : props.field.visible;

    const localValue = ref(props.modelValue);

    const rules = computed(() => {
      if (props.field.validation) {
        return {
          localValue: props.field.validation,
        };
      }
      return { localValue: [] };
    });

    const v$ = useVuelidate(rules, { localValue });

    const hasError = computed(() => v$.value.localValue.$error);

    const errorMessage = computed(() => {
      return v$.value.localValue.$errors
        ? v$.value.localValue.$errors[0].$message
        : "";
    });

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("update:modelValue", target.value);
    };

    return {
      hasDescription,
      hasError,
      errorMessage,
      handleInput,
      localValue,
      v$,
      isVisible,
    };
  },
});
</script>
<style scoped>
.field-container {
  margin-bottom: 1rem;
}

.field-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.field-description {
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.required {
  color: #dc3545;
  margin-left: 0.25rem;
}

.edit-button {
  margin-left: 0.5rem;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
