<!-- client/src/components/signup/se/GeneratorReviewComponent.vue -->

<template>
  <div class="generator-review card p-4 mb-3" novalidate>
    <!-- Basic Information -->
    <h4>{{ $t("signup.se.generator_review.basic_information") }}</h4>

    <DynamicGeneratorField
      v-for="field in gridOwnerFields"
      :key="field.title"
      :field="field"
      v-model="
        localGenerator.application_information.go_application[field.model]
      "
      translationPrefix="signup.se"
    />

    <DynamicGeneratorField
      v-for="field in gridOwnerAddressFields"
      :key="field.title"
      :field="field"
      v-model="
        localGenerator.application_information.go_application
          .grid_owner_address[field.model]
      "
      translationPrefix="signup.se"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, watch, type PropType } from "vue";
import type { Account } from "@/types";
import { useI18n } from "vue-i18n";
import DynamicGeneratorField from "@/components/signup/DynamicGeneratorField.vue";
import type { GeneratorFieldConfig } from "@/components/signup/DynamicGeneratorField.vue";
import { useValidators } from "@/composables/useValidators";
import { getGridFields } from "./grid-fields";

export default defineComponent({
  name: "GeneratorReviewComponent",
  components: {
    DynamicGeneratorField,
  },
  props: {
    generatorIndex: {
      type: Number,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    accountData: {
      type: Object as () => Account,
      required: true,
    },
  },
  emits: ["handleUpdateGenerator"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { gridOwnerFields, gridOwnerAddressFields } = getGridFields(t);

    const generator = computed(() => {
      return props.accountData.companies[props.companyIndex].generators[
        props.generatorIndex
      ];
    });

    const createEmptyUnit = () => ({
      device_name: "",
      device_power: 0,
      grid_contract_start_date: "",
      property_designation: "",
      postal_code: "",
      city: "",
      technology: null,
      estimated_annual_production: null,
    });

    // Initialize with default values
    const localGenerator = reactive({
      ...generator.value,
      application_information: {
        ...generator.value.application_information,
        go_application: {
          ...generator.value.application_information?.go_application,
          grid_owner_address: {
            ...generator.value.application_information?.go_application
              ?.grid_owner_address,
          },
        },
      },
    });

    const canAddMoreUnits = computed(() => {
      return (
        localGenerator.application_information.new_production_unit_applications
          .length < 2
      );
    });

    const addProductionUnit = () => {
      if (canAddMoreUnits.value) {
        localGenerator.application_information.new_production_unit_applications.push(
          createEmptyUnit()
        );
      }
    };

    const removeProductionUnit = (index: number) => {
      if (index > 0) {
        localGenerator.application_information.new_production_unit_applications.splice(
          index,
          1
        );
      }
    };

    watch(
      localGenerator,
      (newValue) => {
        emit("handleUpdateGenerator", newValue);
      },
      { deep: true }
    );

    return {
      localGenerator,
      gridOwnerFields,
      gridOwnerAddressFields,
      canAddMoreUnits,
      addProductionUnit,
      removeProductionUnit,
    };
  },
});
</script>

<style scoped lang="scss">
.generator-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
    padding: 0;
  }

  .form-check {
    margin-bottom: 0.5rem;
  }

  h4 {
    margin-bottom: 1rem;
  }

  .border {
    border-color: #dee2e6 !important;
  }

  .production-unit {
    background-color: #f8f9fa;
  }
}

@media (max-width: 575.98px) {
  .generator-review {
    .col-form-label {
      margin-top: 1rem;
    }
  }
  h4 {
    font-size: 1.2rem;
  }
}
</style>
