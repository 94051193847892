<template>
  <div
    v-if="accountData.admin_account_id && selectedCompany"
    class="alert alert-subtle-warning text-center"
  >
    <div class="form-group">
      <label>{{ $t("dashboard.internal_role") }}</label>
      <select
        :value="accountData.internal_role"
        @change="updateInternalRole"
        class="form-control"
      >
        <option value="user">{{ $t("dashboard.user") }}</option>
        <option value="super_admin">{{ $t("dashboard.super_admin") }}</option>
      </select>
    </div>
    <!-- Company Fee Model Selection -->
    <div class="form-group">
      <label>{{ $t("dashboard.fee_model") }}</label>
      <div class="d-flex align-items-center">
        <select
          v-model="selectedFeeModel"
          class="form-control mr-2"
          @change="updateFeeModel"
        >
          <option value="standard">{{ $t("dashboard.standard") }}</option>
          <option value="special_free">
            {{ $t("dashboard.special_free") }}
          </option>
          <option value="special_1eur_per_mw">
            {{ $t("dashboard.special_1eur_per_mw") }}
          </option>
          <option value="custom_percentage">
            {{ $t("dashboard.custom_percentage") }}
          </option>
        </select>
        <input
          v-if="selectedFeeModel === 'custom_percentage'"
          type="number"
          v-model.number="customPercentage"
          class="form-control ml-2"
          min="5"
          max="20"
          step="1"
          @input="updateCustomPercentage"
        />
        <span v-if="selectedFeeModel === 'custom_percentage'" class="ml-2"
          >%</span
        >
      </div>
      <small
        v-if="selectedFeeModel === 'custom_percentage'"
        class="form-text text-muted"
      >
        {{ $t("dashboard.custom_percentage_hint") }}
      </small>
    </div>
    <!-- Registry Excluded Devices -->
    <div class="form-group">
      <label>{{ $t("dashboard.registry_excluded_devices") }}</label>
      <input
        type="text"
        :value="selectedCompany.registry_excluded_devices"
        @input="updateRegistryExcludedDevices"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>{{ $t("dashboard.nda_signed_link") }}</label>
      <input
        type="text"
        :value="selectedCompany.nda_signed_link"
        @input="updateNdaSignedLink"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label>{{ $t("account.comment") }}</label>
      <input
        type="text"
        :value="accountData.comment"
        @input="updateComment"
        class="form-control"
      />
    </div>
    <!-- Add this after the Comment field and before the Forward Sales Enabled section -->
    <div class="form-group">
      <label>{{ $t("company.vat") }}</label>
      <input
        type="text"
        :value="selectedCompany.vat"
        @input="updateCompanyVat"
        class="form-control"
      />
    </div>

    <!-- Forward Sales Enabled Radio Buttons-->
    <div class="form-group">
      <label>Forward Sales Enabled?</label>
      <div>
        <input
          type="radio"
          id="forwardSalesYes"
          :value="true"
          :checked="selectedCompany.forward_sales_enabled === true"
          @change="updateForwardSalesEnabled(true)"
        />
        <label for="forwardSalesYes">{{ $t("common.yes") }}</label>
        <input
          type="radio"
          id="forwardSalesNo"
          :value="false"
          :checked="selectedCompany.forward_sales_enabled === false"
          @change="updateForwardSalesEnabled(false)"
        />
        <label for="forwardSalesNo">{{ $t("common.no") }}</label>
      </div>
    </div>

    <!-- Is Test Company Radio Buttons -->
    <div class="form-group">
      <label>{{ $t("dashboard.is_test_company") }}</label>
      <div>
        <input
          type="radio"
          id="testYes"
          :value="true"
          :checked="selectedCompany.is_test_company === true"
          @change="updateIsTestCompany(true)"
        />
        <label for="testYes">{{ $t("common.yes") }}</label>
        <input
          type="radio"
          id="testNo"
          :value="false"
          :checked="selectedCompany.is_test_company === false"
          @change="updateIsTestCompany(false)"
        />
        <label for="testNo">{{ $t("common.no") }}</label>
      </div>
    </div>
    <button class="btn btn-primary" @click="updateAccountData">
      {{ $t("dashboard.save_changes") }}
    </button>

    <!-- Custom contract upload -->
    <InfoCardComponent buttonText="Open">
      <template #title>
        <div class="flex-1">
          <h3>Add custom contract</h3>
        </div>
      </template>
      <template #content>
        <AdminContractUploadComponent
          :accountData="accountData"
          :companyIndex="companyIndex"
          @updateAccount="$emit('updateAccount', $event)"
        />
      </template>
    </InfoCardComponent>

    <!-- Display of All Tracking Information for the Account -->
    <InfoCardComponent buttonText="Show All Trackings">
      <template #title>
        <div class="flex-1">
          <h3>{{ $t("dashboard.all_trackings") }}</h3>
        </div>
      </template>
      <template #content>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>{{ $t("common.id") }}</th>
              <th>{{ $t("dashboard.referrer_id") }}</th>
              <th>{{ $t("dashboard.utm_source") }}</th>
              <th>{{ $t("dashboard.utm_medium") }}</th>
              <th>{{ $t("dashboard.utm_term") }}</th>
              <th>{{ $t("dashboard.utm_content") }}</th>
              <th>{{ $t("dashboard.utm_campaign") }}</th>
              <th>{{ $t("dashboard.created_at") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tracking in accountData.trackings" :key="tracking.id">
              <td>{{ tracking.id }}</td>
              <td>
                <input
                  type="text"
                  v-model="tracking.referrer_id"
                  class="form-control"
                />
                <button
                  class="btn btn-primary"
                  @click="updateTracking(tracking)"
                >
                  {{ $t("dashboard.save_changes") }}
                </button>
              </td>
              <td>{{ tracking.utm_source }}</td>
              <td>{{ tracking.utm_medium }}</td>
              <td>{{ tracking.utm_term }}</td>
              <td>{{ tracking.utm_content }}</td>
              <td>{{ tracking.utm_campaign }}</td>
              <td>{{ tracking.created_at }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </InfoCardComponent>
    <!-- Transfer Company Assets -->
    <label for="companySelect">
      {{ $t("dashboard.admin_only_transfer_and_delete_company") }}
    </label>
    <select
      v-model="selectedTransferCompanyId"
      id="companySelect"
      class="form-control"
    >
      <option
        v-for="company in accountData.companies"
        :key="company.id"
        :value="company.id"
      >
        {{
          $t("dashboard.transfer_to", { name: company.name, id: company.id })
        }}
      </option>
    </select>
    <button class="btn btn-primary mt-2" @click="confirmTransfer">
      {{ $t("dashboard.transfer") }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch, inject, computed } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import type { Account, ConversionTracking, Company } from "@/types";
import InfoCardComponent from "@/components/common/InfoCardComponent.vue";
import AdminContractUploadComponent from "@/components/dashboard/AdminContractUploadComponent.vue";

export default defineComponent({
  name: "AdminCompanyManagementComponent",
  props: {
    accountData: {
      type: Object as PropType<Account>,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
  },
  components: {
    InfoCardComponent,
    AdminContractUploadComponent,
  },
  emits: ["updateAccount", "updateCompany"],
  setup(props, { emit }) {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const selectedFeeModel = ref("standard");
    const customPercentage = ref(5);
    const selectedTransferCompanyId = ref<number | null>(null);

    const selectedCompany = computed(
      () => props.accountData.companies[props.companyIndex]
    );

    const parseFeeModel = (feeModel: string | null) => {
      if (!feeModel) {
        selectedFeeModel.value = "standard";
        customPercentage.value = 5;
        return;
      }
      const match = feeModel.match(/^special_(\d{1,2})pct$/);
      if (match) {
        selectedFeeModel.value = "custom_percentage";
        customPercentage.value = parseInt(match[1], 10);
      } else {
        selectedFeeModel.value = feeModel;
      }
    };

    watch(
      () => selectedCompany.value,
      (newCompany) => {
        if (newCompany) {
          parseFeeModel(newCompany.fee_model);
        }
      },
      { immediate: true }
    );

    const updateFeeModel = () => {
      if (selectedCompany.value) {
        let newFeeModel;
        if (selectedFeeModel.value === "custom_percentage") {
          newFeeModel = `special_${customPercentage.value}pct`;
        } else {
          newFeeModel = selectedFeeModel.value;
        }
        emit("updateCompany", {
          ...selectedCompany.value,
          fee_model: newFeeModel,
        });
      }
    };

    const updateCustomPercentage = () => {
      customPercentage.value = Math.min(
        99,
        Math.max(1, Math.round(customPercentage.value))
      );
      updateFeeModel();
    };

    const updateInternalRole = (event: Event) => {
      const value = (event.target as HTMLSelectElement).value;
      emit("updateAccount", { ...props.accountData, internal_role: value });
    };

    const updateRegistryExcludedDevices = (event: Event) => {
      const value = (event.target as HTMLInputElement).value;
      emit("updateCompany", {
        ...selectedCompany.value,
        registry_excluded_devices: value,
      });
    };

    const updateNdaSignedLink = (event: Event) => {
      const value = (event.target as HTMLInputElement).value;
      emit("updateCompany", {
        ...selectedCompany.value,
        nda_signed_link: value,
      });
    };

    const updateComment = (event: Event) => {
      const value = (event.target as HTMLInputElement).value;
      emit("updateAccount", { ...props.accountData, comment: value });
    };

    const updateCompanyVat = (event: Event) => {
      const value = (event.target as HTMLInputElement).value;
      emit("updateCompany", {
        ...selectedCompany.value,
        vat: value,
      });
    };

    const updateIsTestCompany = (value: boolean) => {
      emit("updateCompany", {
        ...selectedCompany.value,
        is_test_company: value,
      });
    };

    const updateForwardSalesEnabled = (value: boolean) => {
      emit("updateCompany", {
        ...selectedCompany.value,
        forward_sales_enabled: value,
      });
    };

    const updateTracking = async (tracking: ConversionTracking) => {
      const confirmed = window.confirm(
        `Are you sure you want to update tracking id ${tracking.id} to have referrer ${tracking.referrer_id}?`
      );
      if (confirmed) {
        try {
          const response = await goSolidApi.post(
            `/api/admin/tracking/${tracking.id}`,
            { referrer_id: tracking.referrer_id }
          );
          if (response.status === 200) {
            window.location.reload();
          } else {
            throw new Error("Error updating tracking");
          }
        } catch (error: unknown) {
          globalErrorHandler(error);
        }
      }
    };

    const confirmTransfer = () => {
      if (selectedTransferCompanyId.value) {
        const selectedTransferCompany = props.accountData.companies.find(
          (company: Company) => company.id === selectedTransferCompanyId.value
        );
        const confirmed = window.confirm(
          `Are you sure you want to transfer all assets from
          ${selectedCompany.value.name}, ID ${selectedCompany.value.id} to company
          ${selectedTransferCompany?.name}, ID: ${selectedTransferCompanyId.value}?`
        );
        if (confirmed) {
          transferAssets(selectedTransferCompanyId.value);
        }
      }
    };

    const transferAssets = async (company_id: number) => {
      try {
        const response = await goSolidApi.post(
          `/api/admin/company/${selectedCompany.value.id}/transfer_assets_and_delete_company/${company_id}`,
          {}
        );
        if (response.status === 200) {
          window.location.reload();
        } else {
          throw new Error("Error transferring assets");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    const updateAccountData = async () => {
      let registryExcludedDevices =
        selectedCompany.value.registry_excluded_devices;
      if (typeof registryExcludedDevices === "string") {
        registryExcludedDevices = (registryExcludedDevices as string).split(
          ","
        );
      }
      try {
        const updatedSelectedCompany = {
          ...selectedCompany.value,
          registry_excluded_devices: registryExcludedDevices,
        };
        const payload = {
          ...props.accountData,
          companies: props.accountData.companies.map(
            (company: Company, index: number) => {
              if (index === props.companyIndex) {
                return updatedSelectedCompany;
              }
              return company;
            }
          ),
        };

        const response = await goSolidApi.post(
          `/api/admin/company/${updatedSelectedCompany.id}`,
          {
            accountData: payload,
          }
        );
        emit("updateAccount", response.data.accountData);
        alert("Account data updated successfully.");
      } catch (error: unknown) {
        globalErrorHandler(error);
        alert("Failed to update account data.");
      }
    };

    return {
      selectedCompany,
      selectedTransferCompanyId,
      confirmTransfer,
      updateAccountData,
      updateTracking,
      updateCustomPercentage,
      updateFeeModel,
      customPercentage,
      selectedFeeModel,
      updateInternalRole,
      updateRegistryExcludedDevices,
      updateNdaSignedLink,
      updateComment,
      updateCompanyVat,
      updateIsTestCompany,
      updateForwardSalesEnabled,
    };
  },
});
</script>
