<!-- client/src/views/admin/CertificatesView.vue -->
<template>
  <div id="AdminCertificates" class="container">
    <div class="row justify-content-between align-items-center mb-3 mt-4">
      <div class="col-auto">
        <h1>Certificates</h1>
      </div>
      <div class="col-12 mb-3">
        <div class="alert alert-info text-dark">
          <strong>Search fields:</strong> ID, Registry ID, Company ID, Device
          EIC, Production Device Name, Company Name
        </div>
        <div class="alert alert-light border text-dark">
          <strong>Color coding:</strong>
          <div class="form-check form-check-inline ms-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="showDeleted"
              v-model="statusFilters.deleted"
              @change="handleStatusFilterChange"
            />
            <label class="form-check-label" for="showDeleted">
              <span class="badge bg-danger me-2">Deleted</span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="showSold"
              v-model="statusFilters.sold"
              @change="handleStatusFilterChange"
            />
            <label class="form-check-label" for="showSold">
              <span class="badge bg-success me-2">Sold</span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="showTransferred"
              v-model="statusFilters.transferred"
              @change="handleStatusFilterChange"
            />
            <label class="form-check-label" for="showTransferred">
              <span class="badge bg-primary me-2">Transferred for selling</span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="showWaitingToBeSold"
              v-model="statusFilters.waiting"
              @change="handleStatusFilterChange"
            />
            <label class="form-check-label" for="showWaitingToBeSold">
              <span class="badge bg-warning me-2">Waiting to be sold</span>
            </label>
          </div>
        </div>
        <div class="alert alert-light border text-dark">
          <strong>Total certificates count (all pages):</strong>
          {{ totalCertificatesCount }}
        </div>
      </div>
      <div class="col-auto">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Search certificates..."
            v-model="searchQuery"
            @input="debouncedSearch"
          />
          <select
            class="form-select"
            style="max-width: 200px"
            v-model="selectedMonth"
            @change="handleSearch"
          >
            <option value="">All Transfer Deadlines</option>
            <option
              v-for="month in transferMonths"
              :key="month.value"
              :value="month.value"
            >
              {{ month.label }}
            </option>
          </select>
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="toggleColumnSelector"
          >
            Columns
          </button>
        </div>
      </div>
    </div>

    <!-- Column Selector Modal -->
    <div
      class="modal fade"
      id="columnSelectorModal"
      tabindex="-1"
      ref="columnModal"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Columns</h5>
            <button
              type="button"
              class="btn-close"
              @click="toggleColumnSelector"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4">
                <h6>Certificate Fields</h6>
                <div
                  class="form-check"
                  v-for="field in certificateFields"
                  :key="field"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="field"
                    v-model="selectedColumns.certificate[field]"
                  />
                  <label class="form-check-label" :for="field">{{
                    field
                  }}</label>
                </div>
              </div>
              <div class="col-md-4">
                <h6>Company Fields</h6>
                <div
                  class="form-check"
                  v-for="field in companyFields"
                  :key="field"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="'company_' + field"
                    v-model="selectedColumns.company[field]"
                  />
                  <label class="form-check-label" :for="'company_' + field">{{
                    field
                  }}</label>
                </div>
              </div>
              <div class="col-md-4">
                <h6>Registry Device Fields</h6>
                <div
                  class="form-check"
                  v-for="field in registryDeviceFields"
                  :key="field"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="'device_' + field"
                    v-model="selectedColumns.registryDevice[field]"
                  />
                  <label class="form-check-label" :for="'device_' + field">{{
                    field
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Certificates Table -->
    <div class="table-container table-responsive scrollbar">
      <table class="table table-hover align-middle">
        <thead>
          <tr>
            <template v-for="field in certificateFields" :key="field">
              <th v-if="selectedColumns.certificate[field]">{{ field }}</th>
            </template>
            <template v-for="field in companyFields" :key="field">
              <th v-if="selectedColumns.company[field]">Company {{ field }}</th>
            </template>
            <template v-for="field in registryDeviceFields" :key="field">
              <th v-if="selectedColumns.registryDevice[field]">
                Device {{ field }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="cert in certificates"
            :key="cert.id"
            :class="{
              'table-danger': cert.deleted_at,
              'table-success': cert.sales_transaction_item_id,
              'table-primary':
                cert.transferred_at &&
                !cert.deleted_at &&
                !cert.sales_transaction_item_id,
              'table-warning':
                !cert.transferred_at &&
                !cert.deleted_at &&
                !cert.sales_transaction_item_id,
            }"
          >
            <template v-for="field in certificateFields" :key="field">
              <td v-if="selectedColumns.certificate[field]">
                {{ formatField(cert[field]) }}
              </td>
            </template>
            <template v-for="field in companyFields" :key="field">
              <td v-if="selectedColumns.company[field]">
                {{ formatField(cert.company?.[field]) }}
              </td>
            </template>
            <template v-for="field in registryDeviceFields" :key="field">
              <td v-if="selectedColumns.registryDevice[field]">
                {{ formatField(cert.registry_device?.[field]) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div class="d-flex justify-content-between align-items-center mt-3">
      <div class="d-flex align-items-center">
        <label class="me-2">Per page:</label>
        <select
          v-model="perPage"
          class="form-select form-select-sm"
          style="width: 80px"
          @change="handlePageChange(1)"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="1000">1000</option>
        </select>
      </div>
      <nav aria-label="Certificate pagination">
        <ul class="pagination mb-0">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a
              class="page-link"
              href="#"
              @click.prevent="handlePageChange(currentPage - 1)"
              >Previous</a
            >
          </li>
          <li
            v-for="p in paginationRange"
            :key="p"
            class="page-item"
            :class="{ active: currentPage === p }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="handlePageChange(p)"
              >{{ p }}</a
            >
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="handlePageChange(currentPage + 1)"
              >Next</a
            >
          </li>
        </ul>
      </nav>
      <div class="text-muted">Total: {{ totalItems }} certificates</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { Modal } from "bootstrap";
import debounce from "lodash/debounce";

interface RegistryDevice {
  id: number;
  device_eic: string;
  device_name: string;
  device_power: number;
  support: boolean;
  technology_code: string;
  registry_status: string;
  [key: string]: string | number | boolean;
}

interface Company {
  id: number;
  name: string;
  registry_code: string;
  has_registered: boolean;
  registry_id: string;
  country: string;
  [key: string]: string | number | boolean;
}

interface Certificate {
  id: number;
  created_at: string;
  updated_at: string;
  registry_id: string;
  registry_status: string;
  current_registry_account_id: string;
  fuel_code: string;
  technology_code: string;
  production_device_code: string;
  issued_at: string;
  expiry_date: string;
  transfer_deadline: string;
  count: number;
  device_eic: string;
  go_type: string;
  production_device_name: string;
  production_device_output: number;
  production_support: boolean;
  company?: Company;
  registry_device?: RegistryDevice;
  deleted_at?: string;
  sales_transaction_item_id?: string;
  transferred_at?: string;
  [key: string]:
    | string
    | number
    | boolean
    | Company
    | RegistryDevice
    | undefined;
}

export default defineComponent({
  name: "AdminCertificatesView",
  setup() {
    const certificates = ref<Certificate[]>([]);
    const searchQuery = ref("");
    const selectedMonth = ref("");
    const columnModal = ref<HTMLElement | null>(null);
    let bsModal: Modal | null = null;
    const currentPage = ref(1);
    const perPage = ref(25);
    const totalItems = ref(0);
    const totalPages = ref(0);
    const totalCertificatesCount = ref(0);

    // Initialize status filters from localStorage or set defaults
    const statusFilters = ref(
      JSON.parse(
        localStorage.getItem("admin_certificates_status_filters") ||
          JSON.stringify({
            deleted: true,
            sold: true,
            transferred: true,
            waiting: true,
          })
      )
    );

    // Define available fields for each model
    const certificateFields = [
      "id",
      "created_at",
      "updated_at",
      "deleted_at",
      "registry_id",
      "registry_status",
      "current_registry_account_id",
      "fuel_code",
      "technology_code",
      "production_device_code",
      "issued_at",
      "expiry_date",
      "transfer_deadline",
      "count",
      "device_eic",
      "go_type",
      "production_device_name",
      "production_device_output",
      "production_support",
      "sales_transaction_item_id",
    ];

    const companyFields = [
      "id",
      "name",
      "registry_code",
      "has_registered",
      "registry_id",
      "country",
    ];

    const registryDeviceFields = [
      "id",
      "device_eic",
      "device_name",
      "device_power",
      "support",
      "technology_code",
      "registry_status",
    ];

    // Initialize selected columns from localStorage or defaults
    const selectedColumns = ref({
      certificate: JSON.parse(
        localStorage.getItem("admin_certificates_columns_cert") || "{}"
      ),
      company: JSON.parse(
        localStorage.getItem("admin_certificates_columns_company") || "{}"
      ),
      registryDevice: JSON.parse(
        localStorage.getItem("admin_certificates_columns_device") || "{}"
      ),
    });

    // Set default selections if none exist
    if (Object.keys(selectedColumns.value.certificate).length === 0) {
      selectedColumns.value.certificate = {
        id: true,
        registry_id: true,
        production_device_name: true,
        count: true,
      };
    }
    if (Object.keys(selectedColumns.value.company).length === 0) {
      selectedColumns.value.company = {
        name: true,
        country: true,
      };
    }
    if (Object.keys(selectedColumns.value.registryDevice).length === 0) {
      selectedColumns.value.registryDevice = {
        device_name: true,
        device_power: true,
      };
    }

    // Watch for changes in selected columns and save to localStorage
    const saveColumnSelections = () => {
      localStorage.setItem(
        "admin_certificates_columns_cert",
        JSON.stringify(selectedColumns.value.certificate)
      );
      localStorage.setItem(
        "admin_certificates_columns_company",
        JSON.stringify(selectedColumns.value.company)
      );
      localStorage.setItem(
        "admin_certificates_columns_device",
        JSON.stringify(selectedColumns.value.registryDevice)
      );
    };

    // Format field values for display
    const formatField = (value: any): string => {
      if (value === null || value === undefined) return "-";
      if (value === true) return "Yes";
      if (value === false) return "No";
      if (typeof value === "object") return JSON.stringify(value);
      return String(value);
    };

    // Generate transfer months (current month + 12 months ahead)
    const transferMonths = computed(() => {
      const months = [];
      const now = new Date();
      for (let i = 0; i < 13; i++) {
        const date = new Date(now.getFullYear(), now.getMonth() + i, 1);
        months.push({
          value: date.toISOString().slice(0, 7), // YYYY-MM format
          label: date.toLocaleString("default", {
            month: "long",
            year: "numeric",
          }),
        });
      }
      return months;
    });

    // Computed property for pagination range
    const paginationRange = computed(() => {
      const range = [];
      const maxButtons = 5;
      const start = Math.max(1, currentPage.value - Math.floor(maxButtons / 2));
      const end = Math.min(totalPages.value, start + maxButtons - 1);

      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      return range;
    });

    // Handle status filter changes
    const handleStatusFilterChange = () => {
      // Save to localStorage
      localStorage.setItem(
        "admin_certificates_status_filters",
        JSON.stringify(statusFilters.value)
      );
      handleSearch();
    };

    // Update fetch certificates to include pagination
    const fetchCertificates = async (search = "", transferMonth = "") => {
      try {
        // If no filters are selected, don't fetch any certificates
        if (!Object.values(statusFilters.value).some((value) => value)) {
          certificates.value = [];
          totalItems.value = 0;
          totalPages.value = 0;
          totalCertificatesCount.value = 0;
          return;
        }

        const response = await goSolidApi.get("/api/admin/certificates", {
          params: {
            search,
            transfer_month: transferMonth,
            page: currentPage.value,
            per_page: perPage.value,
            status_filters: Object.entries(statusFilters.value)
              .filter(([_, value]) => value)
              .map(([key]) => key)
              .join(","),
          },
        });
        if (response.status === 200) {
          certificates.value = response.data.data;
          totalItems.value = response.data.pagination.total;
          totalPages.value = response.data.pagination.total_pages;
          totalCertificatesCount.value =
            response.data.pagination.total_certificates_count;
          // Ensure current page is valid
          if (currentPage.value > totalPages.value) {
            handlePageChange(1);
          }
        }
      } catch (error) {
        console.error("Error fetching certificates:", error);
      }
    };

    // Handle page changes
    const handlePageChange = (page: number) => {
      currentPage.value = page;
      handleSearch();
    };

    // Update search handler
    const handleSearch = () => {
      fetchCertificates(searchQuery.value, selectedMonth.value);
    };

    // Update debounced search
    const debouncedSearch = debounce(handleSearch, 300);

    // Column selector modal toggle
    const toggleColumnSelector = () => {
      if (!bsModal) {
        bsModal = new Modal(columnModal.value as HTMLElement);
      }
      bsModal.toggle();
      saveColumnSelections();
    };

    onMounted(() => {
      handleSearch();
    });

    return {
      certificates,
      searchQuery,
      selectedMonth,
      transferMonths,
      debouncedSearch,
      handleSearch,
      selectedColumns,
      certificateFields,
      companyFields,
      registryDeviceFields,
      formatField,
      toggleColumnSelector,
      columnModal,
      currentPage,
      perPage,
      totalItems,
      totalPages,
      paginationRange,
      handlePageChange,
      totalCertificatesCount,
      statusFilters,
      handleStatusFilterChange,
    };
  },
});
</script>

<style scoped>
#AdminCertificates {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}

.table-container {
  border-radius: 1rem;
  border: 1px solid var(--phoenix-gray-200);
  margin-top: 1rem;
}

.table {
  margin-bottom: 0;
}

.table th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.form-check {
  margin-bottom: 0.5rem;
}

.pagination {
  margin-bottom: 0;
}

.page-link {
  padding: 0.375rem 0.75rem;
}
</style>
