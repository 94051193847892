<!-- client/src/components/dashboard/RequestInvoiceComponent.vue -->

<template>
  <div class="request-invoice">
    <h4 class="fs-7 fw-bold mb-4">{{ $t("requestInvoice.submit_invoice") }}</h4>
    <div class="row text-center mb-3 align-middle g-0">
      <div class="col-12 col-md-3 align-self-center">
        <p class="mb-0 fw-bold">{{ formatMoney(sales + fees + vat) }} €</p>
        <p class="mb-0 fs-9 fw-bold">
          {{ $t("requestInvoice.money_pending") }}
        </p>
      </div>
      <div
        id="sum-explainer"
        class="card col-12 col-md-9 row bg-secondary-subtle p-2 g-0 mt-2 mt-md-0"
      >
        <div class="col-4">
          <p class="mb-0">{{ formatMoney(sales) }} €</p>
          <p class="mb-0 fs-9">{{ $t("requestInvoice.sales_revenue") }}</p>
        </div>
        <div class="col-4">
          <p class="mb-0">{{ formatMoney(vat) }} €</p>
          <p class="mb-0 fs-9">
            {{ $t("requestInvoice.sales_vat") }}
          </p>
        </div>
        <div class="col-4">
          <p class="mb-0">{{ formatMoney(fees) }} €</p>
          <p class="mb-0 fs-9">{{ $t("requestInvoice.service_fees_vat") }}</p>
        </div>
      </div>
    </div>
    <div class="card mt-sm-2 mb-4">
      <div class="card-body p-1 px-3 p-sm-2 px-sm-3 bg-body-highlight">
        <div
          class="d-flex align-items-center"
          @click="toggleCollapse"
          role="button"
        >
          <div class="avatar avatar-x1 me-2">
            <font-awesome-icon
              icon="rectangle-list"
              class="text-secondary-emphasis"
            />
          </div>
          <div class="flex-1 fw-bold fs-9">
            {{ $t("requestInvoice.detailed_overview") }}
          </div>
          <div class="btn btn-text p-2">
            {{ $t("requestInvoice.view_details") }}
          </div>
        </div>
        <div class="text-body-secondary mt-1" v-show="showCollapse">
          <hr />
          <h5 class="fs-8 mb-3">{{ $t("requestInvoice.invoice_items") }}</h5>
          <div class="fs-9 mb-4 card p-2 p-lg-0 overflow-hidden">
            <div
              class="row bg-secondary-subtle mb-2 fw-bold d-none d-lg-flex p-1 g-0"
            >
              <div class="col-4 p-1">
                <p class="mb-0">{{ $t("requestInvoice.item") }}</p>
              </div>
              <div class="col-2 p-1 text-end">
                <p class="mb-0">{{ $t("requestInvoice.quantity") }}</p>
              </div>
              <div class="col-2 p-1 text-end">
                <p class="mb-0">{{ $t("requestInvoice.unit_price") }}</p>
              </div>
              <div class="col-2 p-1 text-end">
                <p class="mb-0">{{ $t("requestInvoice.total_excl_vat") }}</p>
              </div>
              <div class="col-2 p-1 text-end">
                <p class="mb-0">{{ $t("requestInvoice.vat") }}</p>
              </div>
            </div>
            <InvoiceItemDisplayComponent
              v-for="item in pendingInvoiceItems.filter(
                (item) => item.unit_price < 0
              )"
              :key="item.id"
              :invoiceItem="item"
            />
          </div>
          <h5 class="fs-8 mb-3">
            {{ $t("requestInvoice.offset_service_fees") }}
          </h5>
          <div class="fs-9 mb-3 card p-2 p-lg-0 overflow-hidden">
            <div
              class="row bg-secondary-subtle mb-2 fw-bold d-none d-lg-flex p-1 g-0"
            >
              <div class="col-4 p-1">
                <p class="mb-0">{{ $t("requestInvoice.item") }}</p>
              </div>
              <div class="col-2 p-1 text-end">
                <p class="mb-0">{{ $t("requestInvoice.quantity") }}</p>
              </div>
              <div class="col-2 p-1 text-end">
                <p class="mb-0">{{ $t("requestInvoice.unit_price") }}</p>
              </div>
              <div class="col-2 p-1 text-end">
                <p class="mb-0">{{ $t("requestInvoice.total_excl_vat") }}</p>
              </div>
              <div class="col-2 p-1 text-end">
                <p class="mb-0">{{ $t("requestInvoice.vat") }}</p>
              </div>
            </div>
            <InvoiceItemDisplayComponent
              v-for="item in pendingInvoiceItems.filter(
                (item) => item.unit_price > 0
              )"
              :key="item.id"
              :invoiceItem="item"
            />
          </div>
        </div>
      </div>
    </div>

    <hr />

    <h4 class="fs-8 mb-3">{{ $t("requestInvoice.invoice_details") }}</h4>

    <form @submit.prevent="requestInvoice" novalidate>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="mb-3">
            <label for="invoiceNumber" class="form-label fs-9 p-0 text-body">{{
              $t("requestInvoice.invoice_number")
            }}</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.localInvoiceNumber.$error }"
              id="invoiceNumber"
              v-model="localInvoiceNumber"
              @blur="v$.localInvoiceNumber.$touch"
              :placeholder="$t('requestInvoice.invoice_number_placeholder')"
            />
            <div
              class="invalid-feedback"
              v-for="error of v$.localInvoiceNumber.$errors"
              :key="error.$uid"
            >
              {{ error.$message }}
            </div>
          </div>
          <!-- No need to show VAT code field to Estonian private individuals -->
          <div
            class="mb-3"
            v-if="company.country != 'EE' || company.registry_code?.length == 8"
          >
            <label for="vat" class="form-label fs-9 p-0 text-body">{{
              $t("requestInvoice.vat_number")
            }}</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.localVat.$error }"
              id="vat"
              v-model="localVat"
              :placeholder="$t('requestInvoice.vat_number_placeholder')"
              @blur="v$.localVat.$touch"
            />
            <div
              class="invalid-feedback"
              v-for="error of v$.localVat.$errors"
              :key="error.$uid"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="mb-3">
            <label for="bankAccount" class="form-label fs-9 p-0 text-body">
              {{ $t("requestInvoice.bank_account_iban") }}
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.localBankAccount.$error }"
              id="bankAccount"
              v-model="localBankAccount"
              :placeholder="$t('requestInvoice.bank_account_placeholder')"
              @blur="v$.localBankAccount.$touch"
            />
            <div
              class="invalid-feedback"
              v-for="error of v$.localBankAccount.$errors"
              :key="error.$uid"
            >
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 mb-3">
          <label for="paymentHeader" class="form-label fs-9 p-0 text-body">{{
            $t("requestInvoice.contact_block_on_invoice")
          }}</label>
          <textarea
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.localPaymentHeader.$error }"
            id="paymentHeader"
            v-model="localPaymentHeader"
            :placeholder="$t('requestInvoice.contact_block_placeholder')"
            @blur="v$.localPaymentHeader.$touch"
          ></textarea>
          <div
            class="invalid-feedback"
            v-for="error of v$.localPaymentHeader.$errors"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary w-100 mb-3">
        {{ $t("requestInvoice.submit_invoice") }}
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs, ref, computed, inject } from "vue";
import type { Company, InvoiceItem } from "@/types";
import { formatMoney } from "@/composables/formatUtils";
import InvoiceItemDisplayComponent from "@/components/common/InvoiceItemDisplayComponent.vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { useValidators } from "@/composables/useValidators";
import {
  calculateTotalAmountFromInvoiceItemsWithVAT,
  calculateTotalAmountFromInvoiceItems,
  feesFilter,
  salesFilter,
} from "@/composables/money/moneyUtils";

export default defineComponent({
  name: "RequestInvoiceComponent",
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    pendingInvoiceItems: {
      type: Array as PropType<InvoiceItem[]>,
      required: true,
    },
    defaultInvoiceNumber: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    InvoiceItemDisplayComponent,
  },
  emits: ["invoice-requested"],
  setup(props, { emit }) {
    const EE_VAT_FRACTION = 0.22;
    const EU_EXPORT_VAT_FRACTION = 0.0;
    const { t } = useI18n();
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const { company } = toRefs(props);
    const localVat = ref(company.value.vat);
    const localBankAccount = ref(company.value.bank_account);
    const localPaymentHeader = ref(company.value.payment_header);
    const localInvoiceNumber = ref(props.defaultInvoiceNumber);
    const showCollapse = ref(false);

    // const salesFilter = (item: InvoiceItem) => item.unit_price < 0;
    const sales = computed(() => {
      return calculateTotalAmountFromInvoiceItems(
        props.pendingInvoiceItems,
        salesFilter
      );
    });

    // const feesFilter = (item: InvoiceItem) => item.unit_price > 0;
    const fees = computed(() => {
      return calculateTotalAmountFromInvoiceItemsWithVAT(
        props.pendingInvoiceItems,
        feesFilter
      );
    });

    const localVatFraction = computed(() => {
      if (company.value.country === "EE") {
        return localVat.value ? EE_VAT_FRACTION : 0;
      } else {
        return EU_EXPORT_VAT_FRACTION;
      }
    });

    const vat = computed(() => {
      return props.pendingInvoiceItems.reduce((acc, item) => {
        if (item.unit_price < 0) {
          return acc + item.amount * -item.unit_price * localVatFraction.value;
        } else {
          return acc;
        }
      }, 0);
    });

    const toggleCollapse = () => {
      showCollapse.value = !showCollapse.value;
    };

    // INPUT VALIDATION SECTION
    const {
      required,
      estonianVATNumber,
      latvianVATNumber,
      lithuanianVATNumber,
      iban,
    } = useValidators(t);

    const vat_check = computed(() => {
      if (company.value.country === "EE") {
        return estonianVATNumber;
      } else if (company.value.country === "LV") {
        return latvianVATNumber;
      } else if (company.value.country === "LT") {
        return lithuanianVATNumber;
      } else {
        return null;
      }
    });

    const rules = {
      localInvoiceNumber: { required },
      localVat: { vat_check }, // Not required
      localBankAccount: { required, iban },
      localPaymentHeader: { required },
    };

    const v$ = useVuelidate(rules, {
      localInvoiceNumber,
      localVat,
      localBankAccount,
      localPaymentHeader,
    });

    const checkValidity = () => {
      v$.value.$touch();
      console.log(v$.value);
      return !v$.value.$error;
    };

    async function requestInvoice() {
      if (!checkValidity()) {
        return;
      }
      try {
        const response = await goSolidApi.post(
          `/api/company/${company.value.id}/generate-invoice`,
          {
            invoice_number: localInvoiceNumber.value,
            vat_number: localVat.value,
            bank_account: localBankAccount.value,
            payment_header: localPaymentHeader.value,
          }
        );
        if (response.status === 200) {
          emit("invoice-requested");
        } else {
          console.error("Error requesting invoice");
          throw new Error("Error requesting invoice");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    }

    return {
      showCollapse,
      toggleCollapse,
      requestInvoice,
      localVat,
      localBankAccount,
      localPaymentHeader,
      localInvoiceNumber,
      sales,
      fees,
      vat,
      formatMoney,
      v$,
    };
  },
});
</script>

<style scoped>
form {
  .form-label {
    text-transform: none;
  }
}
#paymentHeader {
  min-height: 10rem;
}

#sum-explainer {
  flex-direction: row;
}
</style>
