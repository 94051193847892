<!-- client/src/components/signup/lv/ContractReviewConfigurationComponent.vue -->

<template>
  <ContractReviewDisplayComponent
    :accountData="accountData"
    :contractsToShow="contracts"
    :setLoading="setLoading"
    :signerFields="signerFields"
    :companyFields="companyFields"
    :generatorFields="generatorFields"
    :contractState="contractState"
    :hasGenerator="hasGenerator"
    @createContainerForStartSigningReady="
      $emit('createContainerForStartSigningReady', $event)
    "
  />
</template>

<script lang="ts">
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { Account, Document } from "@/types";
import { useRoute } from "vue-router";
import { defineComponent, reactive, computed, PropType } from "vue";
import ContractReviewDisplayComponent from "@/components/signup/ContractReviewDisplayComponent.vue";
import { useI18n } from "vue-i18n";
import { getGeneratorFields } from "./generator-fields";

export default defineComponent({
  name: "ContractReviewConfigurationComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    setLoading: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    },
    addNewDevice: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ContractReviewDisplayComponent,
  },
  emits: ["createContainerForStartSigningReady"],
  setup(props) {
    const { t } = useI18n();
    const isAdmin = !!props.accountData.admin_account_id;
    const signerFields = reactive([
      { label: t("account.name"), value: props.accountData.name },
      { label: t("account.email"), value: props.accountData.email },
      {
        label: t("account.phone_number"),
        value: props.accountData.phone_number,
      },
    ]);
    const { companyIndex } = useCompanyFinder(props.accountData);
    const selectedCompany = computed(
      () => props.accountData.companies[companyIndex.value]
    );
    const companyFields = reactive([
      { label: t("company.name"), value: selectedCompany.value.name },
      {
        label: t("company.registry_code"),
        value: selectedCompany.value.registry_code,
      },
      { label: t("company.vat"), value: selectedCompany.value.vat },
      { label: t("company.address"), value: selectedCompany.value.address },
    ]);
    const { nullableSelectedGenerator } = useGeneratorFinder(props.accountData);
    const hasGenerator = computed(() => {
      return nullableSelectedGenerator.value.generator !== null;
    });
    const generatorFieldsDef = getGeneratorFields(t).generatorFields;

    let generatorFields: any = [];
    if (nullableSelectedGenerator.value.generator) {
      generatorFields = generatorFieldsDef.map((field) => ({
        label: t(`signup.lv.generator_review.${field.title}.title`),
        value: nullableSelectedGenerator.value.generator?.[field.model],
      }));
    }

    const route = useRoute();

    const addAuthorizationAgreement = computed(() => {
      return route.query.addAuthorizationAgreement === "true";
    });
    const contractState = computed(() => {
      if (props.addNewDevice) {
        return 2;
      }
      return 1;
    });

    const hasSignedOptionalContract = (selectedContract: string) => {
      return selectedCompany.value?.documents.some(
        (document: Document) =>
          document.status === "completed" && // The contract needs to be signed
          document.contract_keys.includes(selectedContract) // and if it has our contract key, we want to return true
      );
    };

    const shouldSignOptionalContractIfOptional = (
      optionalContractKey: string | null
    ): boolean => {
      return (
        !optionalContractKey || !hasSignedOptionalContract(optionalContractKey)
      );
    };

    const includeDocument = (
      states: number[],
      optionalContractKey: string | null = null
    ) =>
      computed(() => {
        return (
          states.includes(contractState.value) &&
          shouldSignOptionalContractIfOptional(optionalContractKey)
        );
      });

    // https://drive.google.com/drive/u/0/folders/1SX235_DEzJSBvmeJssPaWGSk2o_MyNfY
    const contracts = reactive([
      [
        {
          id: 1,
          key: "lv_authotization_application",
          contract_title: t(
            "signup.contract_review_component.lv.lv_account_authorization_form_title"
          ),
          contract_description: t(
            "signup.contract_review_component.lv.lv_account_authorization_form_text"
          ),
          include: includeDocument([1, 2], "lv_account_authorization_form")
            .value,
          can_include: true,
        },
        {
          id: 2,
          key: "lv_soldera_membership_agreement",
          contract_title: t(
            "signup.contract_review_component.lv.lv_soldera_membership_agreement_title"
          ),
          contract_description: t(
            "signup.contract_review_component.lv.lv_soldera_membership_agreement_text"
          ),
          include: includeDocument([1, 2], "lv_soldera_membership_agreement")
            .value,
          can_include: true,
        },
        {
          id: 3,
          key: "lv_account_holder_registration_form",
          contract_title: t(
            "signup.contract_review_component.lv.lv_soldera_account_holder_registration_form_title"
          ),
          contract_description: t(
            "signup.contract_review_component.lv.lv_soldera_account_holder_registration_form_text"
          ),
          include: includeDocument([2], "lv_account_holder_registration_form")
            .value,
          can_include: true,
        },
        {
          id: 4,
          key: "lv_account_holder_registration_form_auth",
          contract_title: t(
            "signup.contract_review_component.lv.lv_soldera_account_holder_registration_form_title"
          ),
          contract_description: t(
            "signup.contract_review_component.lv.lv_soldera_account_holder_registration_form_text"
          ),
          include: includeDocument(
            [1],
            "lv_account_holder_registration_form_auth"
          ).value,
          can_include: true,
        },
        {
          id: 5,
          key: "lv_production_device_registration_form",
          contract_title: t(
            "signup.contract_review_component.lv.lv_new_device_application_title"
          ),
          contract_description: t(
            "signup.contract_review_component.lv.lv_new_device_application_text"
          ),
          include: includeDocument(
            [2],
            "lv_production_device_registration_form"
          ).value,
          can_include: true,
        },
        {
          id: 6,
          key: "UF_lv_grid_connection_scheme",
          contract_title: t(
            "signup.multiple_upload.UF_lv_grid_connection_scheme"
          ),
          contract_description: t(
            "signup.multiple_upload.UF_lv_grid_connection_scheme_where_to_find"
          ),
          include: includeDocument([2], "UF_lv_grid_connection_scheme").value,
          can_include: true,
        },
        {
          id: 7,
          key: "UF_lv_document_of_device_auxiliaries",
          contract_title: t(
            "signup.multiple_upload.UF_lv_document_of_device_auxiliaries"
          ),
          contract_description: t(
            "signup.multiple_upload.UF_lv_document_of_device_auxiliaries_where_to_find"
          ),
          include: includeDocument([2], "UF_lv_document_of_device_auxiliaries")
            .value,
          can_include: true,
        },
        {
          id: 8,
          key: "UF_lv_power_of_attorney",
          contract_title: t("signup.multiple_upload.UF_lv_power_of_attorney"),
          contract_description: t(
            "signup.multiple_upload.UF_lv_power_of_attorney_where_to_find"
          ),
          include: includeDocument([2], "UF_lv_power_of_attorney").value,
          can_include: true,
        },
      ],
    ]);

    return {
      contracts,
      signerFields,
      companyFields,
      generatorFields,
      contractState,
      hasGenerator,
      isAdmin,
    };
  },
});
</script>

<style scoped lang="scss"></style>
