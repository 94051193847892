<!-- client/src/views/admin/EmailsView.vue -->

<template>
  <div id="AdminEmails" class="container">
    <div class="row justify-content-between align-items-center mb-3 mt-4">
      <div class="col-auto">
        <h1>Scheduled emails</h1>
      </div>
    </div>
    <div class="table-container table-responsive">
      <table id="email-table" class="table table-hover align-middle p-2">
        <thead>
          <tr>
            <th>Updated at</th>
            <th>Email type</th>
            <th>Account</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="email in scheduledEmails" :key="email.id">
            <td>{{ email.updated_at }}</td>
            <td>{{ email.email_type }}</td>
            <td>{{ email.account_id }}</td>
            <td>{{ email.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref } from "vue";
import type { ScheduledEmail } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";

export default defineComponent({
  name: "AdminEmailsView",

  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const scheduledEmails = ref<ScheduledEmail[]>([]);

    onMounted(async () => {
      try {
        const response = await goSolidApi.get("/api/admin/scheduled-emails");
        if (response.status === 200 && response.data) {
          scheduledEmails.value = response.data;
        } else {
          throw new Error("Error fetching scheduled emails.");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    });

    return {
      scheduledEmails,
    };
  },
});
</script>

<style scoped>
#AdminEmails {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}

.table-container {
  border-radius: 1rem;
  border: 1px solid var(--phoenix-gray-200);
  overflow: hidden !important;
  .table > tbody > tr > td:first-child {
    padding-left: 1rem;
  }
}

#email-table {
  margin-bottom: 0;
  .table-active {
    background-color: var(--phoenix-warning-bg-subtle);
    td {
      background-color: #fff3cd;
      box-shadow: none;
    }
  }
}
</style>
