<!-- client/src/components/signup/lv/LVSignupFlow.vue -->

<template>
  <div class="container py-5 pt-3 pt-xl-5">
    <ProgressNavComponent
      v-if="signupStep > 1 && !changingInformationDisabled"
      :steps="navSteps"
      :setStep="setStep"
    />

    <!-- STEP 1: Country selection and onboarding type -->
    <div v-if="shownStep.step_name === StepName.CHOOSE_ONBOARDING">
      <CountryPickerComponent
        :accountData="accountData"
        :handleUpdateAccountData="handleUpdateAccountData"
      />

      <ChooseOnboardingComponent
        :addAuthorizationAgreementFlow="createAuthorizationAccountFlow"
        :addNewDeviceFlow="createNewDeviceFlow"
        :existingCompanies="existingCompanies"
        :countryLocale="'lv'"
        @selectExistingCompany="handleSelectExistingCompany"
        @resetCompanySelection="handleResetCompanySelection"
      />
    </div>

    <!-- STEP 2: Contact review (AUTH Step 2) -->
    <div
      v-if="shownStep.step_name === StepName.NEW_AUTHORIZATION_CONTACT_REVIEW"
    >
      <NewAuthorizationContactReviewConfigurationComponent
        v-if="companyIndex !== -1"
        :key="companyIndex"
        :accountData="accountData"
        :companyIndex="companyIndex"
        @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
        @updateAccountData="handleUpdateAccountData"
        @selectExistingCompany="handleSelectExistingCompany"
        @resetCompanySelection="handleResetCompanySelection"
        class="mb-4"
      />
    </div>

    <!-- STEP 2: Contact review (NEW ACCOUNT/DEVICE Step 2) -->
    <div v-if="shownStep.step_name === StepName.NEW_ACCOUNT_CONTACT_REVIEW">
      <NewAccountContactReviewConfigurationComponent
        v-if="companyIndex !== -1"
        :key="companyIndex"
        :accountData="accountData"
        :companyIndex="companyIndex"
        @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
        @updateAccountData="handleUpdateAccountData"
        @selectExistingCompany="handleSelectExistingCompany"
        @resetCompanySelection="handleResetCompanySelection"
        class="mb-4"
      />
      <AddressReviewComponent
        v-if="generatorIndex !== -1"
        :account-data="accountData"
        :generator-index="generatorIndex"
        :company-index="companyIndex"
        @handleUpdateGenerator="$emit('handleUpdateGenerator', $event)"
      />
    </div>

    <!-- NEW ACCOUNT/DEVICE Step 3: Generator review -->
    <GeneratorReviewComponent
      v-if="
        shownStep.step_name === StepName.GENERATOR_REVIEW &&
        generatorIndex !== -1
      "
      :account-data="accountData"
      :generator-index="generatorIndex"
      :company-index="companyIndex"
      @handleUpdateGenerator="$emit('handleUpdateGenerator', $event)"
    />

    <!-- NEW ACCOUNT/DEVICE Step 3: Generator review -->
    <GridReviewComponent
      v-if="
        shownStep.step_name === StepName.GRID_REVIEW && generatorIndex !== -1
      "
      :account-data="accountData"
      :generator-index="generatorIndex"
      :company-index="companyIndex"
      @handleUpdateGenerator="$emit('handleUpdateGenerator', $event)"
    />

    <!-- AUTH Step 3 / NEW ACCOUNT/DEVICE Step 5: Contract review -->
    <SEContractReviewComponent
      v-if="
        shownStep.step_name === StepName.CONTRACT_REVIEW && companyIndex !== -1
      "
      :accountData="accountData"
      :setLoading="setLoading"
      :addNewDevice="!addAuthorizationAgreement"
      @createContainerForStartSigningReady="storeCreateContainerForStartSigning"
      @disableChangingInformation="handleDisableChangingInformation"
      class="mb-4"
    />

    <!-- Buttons -->
    <div
      v-if="signupStep > 1 && !changingInformationDisabled"
      class="d-flex justify-content-between w-100"
    >
      <button
        class="btn btn-secondary me-2"
        :disabled="isLoading"
        @click="setStep(signupStep - 1)"
      >
        {{ $t("signup.navsteps.back") }}
      </button>

      <button
        class="btn btn-primary"
        :disabled="isLoading"
        @click="
          shownStep.step_name === StepName.CONTRACT_REVIEW
            ? createContainerForStartSigningFunction()
            : setStep(signupStep + 1)
        "
      >
        {{
          shownStep.step_name === StepName.CONTRACT_REVIEW
            ? $t("signup.navsteps.to_signature_page")
            : $t("signup.navsteps.next")
        }}
      </button>
    </div>
    <!-- Loading overlay, shown only when isLoading is true -->
    <LoadingOverlayComponent v-if="isLoading" />
    <!-- AccountDataDebugComponent for debugging -->
    <AccountDataDebugComponent
      :accountData="accountData"
      class="mb-4"
    ></AccountDataDebugComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, inject } from "vue";
import AccountDataDebugComponent from "@/components/common/AccountDataDebugComponent.vue";
import LoadingOverlayComponent from "@/components/common/LoadingOverlayComponent.vue";
import ProgressNavComponent from "@/components/signup/ProgressNavComponent.vue";
import type { Account } from "@/types";
import { useQueryManager } from "@/composables/useQueryManager";
import { useStepFunctions, StepConfig } from "@/composables/useStepFunctions";
import useVuelidate from "@vuelidate/core";
import { accountUpdater } from "@/composables/account/accountUpdater";
import CountryPickerComponent from "@/components/signup/CountryPickerComponent.vue";
import { useI18n } from "vue-i18n";
import ChooseOnboardingComponent from "@/components/signup/ChooseOnboardingComponent.vue";
// TODO fix these imports
import { getGeneratorFields } from "@/components/signup/lv/generator-fields";
import NewAuthorizationContactReviewConfigurationComponent from "@/components/signup/se/NewAuthorizationContactReviewConfigurationComponent.vue";
import NewAccountContactReviewConfigurationComponent from "@/components/signup/se/NewAccountContactReviewConfigurationComponent.vue";
import SEContractReviewComponent from "@/components/signup/se/SEContractReviewComponent.vue";
import GeneratorReviewComponent from "@/components/signup/se/GeneratorReviewComponent.vue";
import AddressReviewComponent from "@/components/signup/se/AddressReviewComponent.vue";
import GridReviewComponent from "./GridReviewComponent.vue";

enum StepName {
  CHOOSE_ONBOARDING,
  NEW_AUTHORIZATION_CONTACT_REVIEW,
  NEW_ACCOUNT_CONTACT_REVIEW,
  GENERATOR_REVIEW,
  GRID_REVIEW,
  CONTRACT_REVIEW,
}

const NEW_DEVICE_UPLOAD_ITEMS = [
  {
    fileKey: "UF_lv_grid_connection_scheme",
  },
  {
    fileKey: "UF_lv_document_of_device_auxiliaries",
  },
  {
    fileKey: "UF_lv_power_of_attorney",
  },
];

const FILE_UPLOAD_URL = "/api/lv/upload-generator-file";

export default defineComponent({
  name: "SESignupFlow",
  components: {
    GeneratorReviewComponent,
    AddressReviewComponent,
    AccountDataDebugComponent,
    NewAuthorizationContactReviewConfigurationComponent,
    NewAccountContactReviewConfigurationComponent,
    SEContractReviewComponent,
    ProgressNavComponent,
    LoadingOverlayComponent,
    CountryPickerComponent,
    ChooseOnboardingComponent,
    GridReviewComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    handleUpdateAccountData: {
      type: Function as unknown as () => (updatedAccountData: Account) => void,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    generatorIndex: {
      type: Number,
      required: true,
    },
    companyIdQueryString: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "handleUpdateCompany",
    "handleUpdateGenerator",
    "createCompanyIfMissing",
    "removeEmptyCompanyIfExists",
    "createGeneratorIfMissing",
  ],
  setup(props, { emit }) {
    const createContainerForStartSigningFunction = ref(() => undefined);

    const isLoading = ref(true);
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const { updateQuery, getQueryValue, parsers } = useQueryManager();
    const signupStep = getQueryValue("step", (value) =>
      value ? parseInt(value, 10) : 1
    );
    const addAuthorizationAgreement = getQueryValue(
      "addAuthorizationAgreement",
      parsers.boolean
    );

    const v$ = useVuelidate();
    const { t } = useI18n();
    const generatorFields = getGeneratorFields(t).generatorFields;
    const isAdmin = !!props.accountData.admin_account_id;

    const existingCompanies = computed(() => {
      return props.accountData.companies.filter(
        (company) =>
          company.id !== undefined &&
          company.country === "SE" &&
          company.documents.length > 0
      );
    });

    const hasGenerator = computed(() => {
      return props.generatorIndex !== -1;
    });

    const postStepCallback = async (config: StepConfig) => {
      try {
        if (checkValidity()) {
          // Since data is valid we can post it if we can post it
          if (config.apiEndpoint) {
            await postAccountData(
              config.apiEndpoint,
              onAccountUpdated,
              props.companyIdQueryString
            );
          }
          return true;
        }
      } catch {
        return false;
      } finally {
        isLoading.value = false;
      }
      return false;
    };

    // TODO Cursor, all these translations need to be updated to SV from LV
    const STEP_CONFIG: Record<StepName, StepConfig> = {
      [StepName.CHOOSE_ONBOARDING]: {
        step_name: StepName.CHOOSE_ONBOARDING,
        title: t("signup.navsteps.se.step1"),
        titleKey: "signup.navsteps.se.step1",
      },
      [StepName.NEW_AUTHORIZATION_CONTACT_REVIEW]: {
        step_name: StepName.NEW_AUTHORIZATION_CONTACT_REVIEW,
        title: t("signup.navsteps.se.authorization.contact_review"),
        titleKey: "signup.navsteps.se.authorization.contact_review",
        apiEndpoint: "/api/se/update-company",
        postStepCallback: postStepCallback,
        preStepCallback: async (_: StepConfig) => {
          console.log("preStepCallback emitted");
          emit("createCompanyIfMissing");
          return true;
        },
      },
      [StepName.NEW_ACCOUNT_CONTACT_REVIEW]: {
        step_name: StepName.NEW_ACCOUNT_CONTACT_REVIEW,
        title: t("signup.navsteps.se.new_device.contact_review"),
        titleKey: "signup.navsteps.se.new_device.contact_review",
        apiEndpoint: "/api/se/update-generator",
        postStepCallback: postStepCallback,
        preStepCallback: async (_: StepConfig) => {
          console.log("preStepCallback emitted");
          emit("createCompanyIfMissing");
          if (!hasGenerator.value) {
            emit("createGeneratorIfMissing");
          }
          return true;
        },
      },
      [StepName.GENERATOR_REVIEW]: {
        step_name: StepName.GENERATOR_REVIEW,
        title: t("signup.navsteps.se.new_device.generator_review"),
        titleKey: "signup.navsteps.se.new_device.generator_review",
        apiEndpoint: "/api/se/update-generator",
        postStepCallback: postStepCallback,
        preStepCallback: async (_: StepConfig) => {
          if (!hasGenerator.value) {
            emit("createGeneratorIfMissing");
          }
          return true;
        },
      },
      [StepName.GRID_REVIEW]: {
        step_name: StepName.GRID_REVIEW,
        title: t("signup.navsteps.se.new_device.grid_review"),
        titleKey: "signup.navsteps.se.new_device.grid_review",
        apiEndpoint: "/api/se/update-generator",
        postStepCallback: postStepCallback,
        preStepCallback: async (_: StepConfig) => {
          if (!hasGenerator.value) {
            emit("createGeneratorIfMissing");
          }
          return true;
        },
      },
      [StepName.CONTRACT_REVIEW]: {
        step_name: StepName.CONTRACT_REVIEW,
        title: t("signup.navsteps.se.contract_review"),
        titleKey: "signup.navsteps.se.contract_review",
        postStepCallback: postStepCallback,
      },
    };

    const flowSteps = computed(() => {
      if (addAuthorizationAgreement.value) {
        return [
          STEP_CONFIG[StepName.CHOOSE_ONBOARDING],
          STEP_CONFIG[StepName.NEW_AUTHORIZATION_CONTACT_REVIEW],
          STEP_CONFIG[StepName.CONTRACT_REVIEW],
        ];
      } else {
        return [
          STEP_CONFIG[StepName.CHOOSE_ONBOARDING],
          STEP_CONFIG[StepName.NEW_ACCOUNT_CONTACT_REVIEW],
          STEP_CONFIG[StepName.GENERATOR_REVIEW],
          STEP_CONFIG[StepName.GRID_REVIEW],
          STEP_CONFIG[StepName.CONTRACT_REVIEW],
        ];
      }
    });

    const { shownStep, setStep, navSteps, invokePreStepCallback } =
      useStepFunctions(flowSteps, signupStep);

    const setLoading = (value: boolean) => {
      isLoading.value = value;
    };

    const onAccountUpdated = async (
      _: Account,
      company_id: number,
      response: any
    ) => {
      isLoading.value = false;

      const { generator_id = undefined } = response.data || {};

      // This is here to only add the new company_id to the query parameters in case of authorization
      // agreement when company was created
      await updateQuery({
        company_id: company_id,
        ...(generator_id && { generator_id: generator_id.toString() }),
      });
    };

    const { postAccountData } = accountUpdater(
      props.accountData,
      props.handleUpdateAccountData
    );

    const checkValidity = () => {
      v$.value.$touch();
      return !v$.value.$error;
    };

    const storeCreateContainerForStartSigning = (
      createContainerForStartSigning: () => undefined
    ) => {
      createContainerForStartSigningFunction.value =
        createContainerForStartSigning;
    };

    const changingInformationDisabled = ref(false);

    const handleDisableChangingInformation = () => {
      changingInformationDisabled.value = true;
    };

    const handleUploadComplete = async (responseData: {
      accountData: Account;
      generator_id: string;
      company_id: string;
    }) => {
      const newAccountData = responseData.accountData;
      const generator_id = responseData.generator_id;
      const company_id = responseData.company_id;
      props.handleUpdateAccountData(newAccountData);
      await updateQuery({
        generator_id: generator_id,
        company_id: company_id,
      });
    };

    const createAuthorizationAccountFlow = async () => {
      await updateQuery({ addAuthorizationAgreement: "true" });
      setStep(2);
    };

    const createNewDeviceFlow = async () => {
      await updateQuery({ addAuthorizationAgreement: "false" });
      setStep(2);
    };

    const handleSelectExistingCompany = async (companyId: number) => {
      isLoading.value = true;
      try {
        await updateQuery({
          company_id: companyId.toString(),
        });
        setStep(2);
        emit("removeEmptyCompanyIfExists");
      } catch (error) {
        globalErrorHandler(error);
      } finally {
        isLoading.value = false;
      }
    };

    const handleResetCompanySelection = () => {
      updateQuery({
        company_id: undefined,
        generator_id: undefined,
      });
      emit("removeEmptyCompanyIfExists");
    };

    onMounted(async () => {
      await invokePreStepCallback();
      isLoading.value = false;
    });

    return {
      navSteps,
      shownStep,
      signupStep,
      setStep,
      isLoading,
      setLoading,
      hasGenerator,
      existingCompanies,
      handleUploadComplete,
      storeCreateContainerForStartSigning,
      createContainerForStartSigningFunction,
      addAuthorizationAgreement,
      createAuthorizationAccountFlow,
      createNewDeviceFlow,
      handleSelectExistingCompany,
      handleResetCompanySelection,
      handleDisableChangingInformation,
      changingInformationDisabled,
      isAdmin,
      generatorFields,
      StepName,
      NEW_DEVICE_UPLOAD_ITEMS,
      FILE_UPLOAD_URL,
    };
  },
});
</script>
