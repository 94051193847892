<!-- client/src/components/dashboard/InvoiceHistoryComponent.vue -->

<template>
  <div class="invoice-history-component">
    <h4 class="fs-7 fw-bold mb-3">
      {{ $t("dashboard.sales_transactions.title") }}
    </h4>
    <p>
      {{ $t("dashboard.sales_transactions.description") }}
      <a
        href="https://www.soldera.org/article/mis-on-paritolutunnistuste-hind-siin-on-moned-avalikud-hinnasignaalid"
        target="_blank"
      >
        {{ $t("dashboard.sales_transactions.description_link") }}</a
      >
    </p>
    <PlaceholderCardComponent
      v-if="sales_transactions.length === 0"
      :title="$t('dashboard.sales_transactions.no_sales_transactions')"
      :subtitle="$t('dashboard.sales_transactions.notificationInfo')"
    />
    <div
      class="d-flex-row border rounded-3 overflow-hidden border-gray-200 fs-9"
      v-else
    >
      <div
        class="row bg-secondary-subtle mb-2 fw-bold d-none d-lg-flex p-1 g-0 align-items-center"
      >
        <div class="col-2 p-1">
          <p class="mb-0">{{ $t("dashboard.sales_transactions.date") }}</p>
        </div>
        <div class="col-2 p-1 text-end">
          <p class="mb-0">{{ $t("dashboard.sales_transactions.item") }}</p>
        </div>
        <div class="col-2 p-1 text-end">
          <p class="mb-0">
            {{ $t("dashboard.sales_transactions.final_unit_price") }}
          </p>
        </div>
        <div class="col-2 p-1 text-end">
          <p class="mb-0">
            {{ $t("dashboard.sales_transactions.offers_taken") }}
          </p>
        </div>
        <div class="col-2 p-1 text-end">
          <p class="mb-0">
            {{ $t("dashboard.sales_transactions.offer_range") }}
          </p>
        </div>
        <div class="col-2 p-1 text-end">
          <p class="mb-0">
            {{ $t("dashboard.sales_transactions.final_country") }}
          </p>
        </div>
      </div>
      <div
        class="row mb-2 p-4 p-lg-1 g-0"
        v-for="sales_transaction in sales_transactions"
        :key="sales_transaction.id"
      >
        <div class="col-6 col-lg-2 px-1 py-1 py-lg-0">
          <p class="mb-0 d-lg-none">
            {{ $t("dashboard.sales_transactions.date") }}
          </p>
          <p
            v-if="!nda_signed_link || nda_signed_link == ''"
            class="p-0 fw-bold"
          >
            {{ formatDateTime(sales_transaction.transaction_at) }}
          </p>
          <a
            v-else
            :href="sales_transaction.transaction_link"
            target="_blank"
            class="p-0 fw-bold"
          >
            {{ formatDateTime(sales_transaction.transaction_at) }}
          </a>
        </div>
        <div class="col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end">
          <p class="mb-0 d-lg-none">
            {{ $t("dashboard.sales_transactions.item") }}
          </p>
          <p class="p-0 fw-bold">{{ sales_transaction.description }}</p>
        </div>
        <div class="col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end">
          <p class="mb-0 d-lg-none">
            {{ $t("dashboard.sales_transactions.final_unit_price") }}
          </p>
          <p class="p-0">{{ formatMoney(sales_transaction.unit_price) }} €</p>
        </div>
        <div class="col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end">
          <p class="mb-0 d-lg-none">
            {{ $t("dashboard.sales_transactions.offers_taken") }}
          </p>
          <p class="p-0">{{ sales_transaction.offers_taken }}</p>
        </div>
        <div class="col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end">
          <p class="mb-0 d-lg-none">
            {{ $t("dashboard.sales_transactions.offer_range") }}
          </p>
          <p class="p-0">
            {{ formatMoney(sales_transaction.min_price) }} -
            {{ formatMoney(sales_transaction.unit_price) }}
          </p>
        </div>
        <div class="col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end">
          <p class="mb-0 d-lg-none">
            {{ $t("dashboard.sales_transactions.final_country") }}
          </p>
          <p class="p-0">{{ sales_transaction.counterparty_country }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, inject } from "vue";
import { SalesTransaction } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { formatDateTime, formatMoney } from "@/composables/formatUtils";
import PlaceholderCardComponent from "@/components/common/PlaceholderCardComponent.vue";

export default defineComponent({
  name: "SalesTransactionsComponent",
  props: {
    company_id: {
      type: Number,
      required: true,
    },
    nda_signed_link: {
      type: String,
      required: false,
    },
  },
  components: {
    PlaceholderCardComponent,
  },
  setup(props) {
    const sales_transactions = ref<SalesTransaction[]>([]);
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;

    async function getSalesTransactions(company_id: number) {
      try {
        const response = await goSolidApi.get(
          `/api/${company_id}/sales_transactions`
        );
        if (response.status === 200) {
          sales_transactions.value = response.data.sales_transactions;
        } else {
          throw new Error("Error fetching invoices");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    }

    onMounted(() => {
      getSalesTransactions(props.company_id);
    });

    return { sales_transactions, formatDateTime, formatMoney };
  },
});
</script>

<style scoped></style>
