import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container py-5 pt-3 pt-xl-5" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = {
  key: 7,
  class: "d-flex justify-content-between w-100"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressNavComponent = _resolveComponent("ProgressNavComponent")!
  const _component_CountryPickerComponent = _resolveComponent("CountryPickerComponent")!
  const _component_ChooseOnboardingComponent = _resolveComponent("ChooseOnboardingComponent")!
  const _component_NewAuthorizationContactReviewConfigurationComponent = _resolveComponent("NewAuthorizationContactReviewConfigurationComponent")!
  const _component_NewAccountContactReviewConfigurationComponent = _resolveComponent("NewAccountContactReviewConfigurationComponent")!
  const _component_AddressReviewComponent = _resolveComponent("AddressReviewComponent")!
  const _component_GeneratorReviewComponent = _resolveComponent("GeneratorReviewComponent")!
  const _component_GridReviewComponent = _resolveComponent("GridReviewComponent")!
  const _component_SEContractReviewComponent = _resolveComponent("SEContractReviewComponent")!
  const _component_LoadingOverlayComponent = _resolveComponent("LoadingOverlayComponent")!
  const _component_AccountDataDebugComponent = _resolveComponent("AccountDataDebugComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.signupStep > 1 && !_ctx.changingInformationDisabled)
      ? (_openBlock(), _createBlock(_component_ProgressNavComponent, {
          key: 0,
          steps: _ctx.navSteps,
          setStep: _ctx.setStep
        }, null, 8, ["steps", "setStep"]))
      : _createCommentVNode("", true),
    (_ctx.shownStep.step_name === _ctx.StepName.CHOOSE_ONBOARDING)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CountryPickerComponent, {
            accountData: _ctx.accountData,
            handleUpdateAccountData: _ctx.handleUpdateAccountData
          }, null, 8, ["accountData", "handleUpdateAccountData"]),
          _createVNode(_component_ChooseOnboardingComponent, {
            addAuthorizationAgreementFlow: _ctx.createAuthorizationAccountFlow,
            addNewDeviceFlow: _ctx.createNewDeviceFlow,
            existingCompanies: _ctx.existingCompanies,
            countryLocale: 'lv',
            onSelectExistingCompany: _ctx.handleSelectExistingCompany,
            onResetCompanySelection: _ctx.handleResetCompanySelection
          }, null, 8, ["addAuthorizationAgreementFlow", "addNewDeviceFlow", "existingCompanies", "onSelectExistingCompany", "onResetCompanySelection"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.shownStep.step_name === _ctx.StepName.NEW_AUTHORIZATION_CONTACT_REVIEW)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.companyIndex !== -1)
            ? (_openBlock(), _createBlock(_component_NewAuthorizationContactReviewConfigurationComponent, {
                key: _ctx.companyIndex,
                accountData: _ctx.accountData,
                companyIndex: _ctx.companyIndex,
                onHandleUpdateCompany: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleUpdateCompany', $event))),
                onUpdateAccountData: _ctx.handleUpdateAccountData,
                onSelectExistingCompany: _ctx.handleSelectExistingCompany,
                onResetCompanySelection: _ctx.handleResetCompanySelection,
                class: "mb-4"
              }, null, 8, ["accountData", "companyIndex", "onUpdateAccountData", "onSelectExistingCompany", "onResetCompanySelection"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.shownStep.step_name === _ctx.StepName.NEW_ACCOUNT_CONTACT_REVIEW)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.companyIndex !== -1)
            ? (_openBlock(), _createBlock(_component_NewAccountContactReviewConfigurationComponent, {
                key: _ctx.companyIndex,
                accountData: _ctx.accountData,
                companyIndex: _ctx.companyIndex,
                onHandleUpdateCompany: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('handleUpdateCompany', $event))),
                onUpdateAccountData: _ctx.handleUpdateAccountData,
                onSelectExistingCompany: _ctx.handleSelectExistingCompany,
                onResetCompanySelection: _ctx.handleResetCompanySelection,
                class: "mb-4"
              }, null, 8, ["accountData", "companyIndex", "onUpdateAccountData", "onSelectExistingCompany", "onResetCompanySelection"]))
            : _createCommentVNode("", true),
          (_ctx.generatorIndex !== -1)
            ? (_openBlock(), _createBlock(_component_AddressReviewComponent, {
                key: 1,
                "account-data": _ctx.accountData,
                "generator-index": _ctx.generatorIndex,
                "company-index": _ctx.companyIndex,
                onHandleUpdateGenerator: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('handleUpdateGenerator', $event)))
              }, null, 8, ["account-data", "generator-index", "company-index"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.shownStep.step_name === _ctx.StepName.GENERATOR_REVIEW &&
        _ctx.generatorIndex !== -1
      )
      ? (_openBlock(), _createBlock(_component_GeneratorReviewComponent, {
          key: 4,
          "account-data": _ctx.accountData,
          "generator-index": _ctx.generatorIndex,
          "company-index": _ctx.companyIndex,
          onHandleUpdateGenerator: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('handleUpdateGenerator', $event)))
        }, null, 8, ["account-data", "generator-index", "company-index"]))
      : _createCommentVNode("", true),
    (
        _ctx.shownStep.step_name === _ctx.StepName.GRID_REVIEW && _ctx.generatorIndex !== -1
      )
      ? (_openBlock(), _createBlock(_component_GridReviewComponent, {
          key: 5,
          "account-data": _ctx.accountData,
          "generator-index": _ctx.generatorIndex,
          "company-index": _ctx.companyIndex,
          onHandleUpdateGenerator: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('handleUpdateGenerator', $event)))
        }, null, 8, ["account-data", "generator-index", "company-index"]))
      : _createCommentVNode("", true),
    (
        _ctx.shownStep.step_name === _ctx.StepName.CONTRACT_REVIEW && _ctx.companyIndex !== -1
      )
      ? (_openBlock(), _createBlock(_component_SEContractReviewComponent, {
          key: 6,
          accountData: _ctx.accountData,
          setLoading: _ctx.setLoading,
          addNewDevice: !_ctx.addAuthorizationAgreement,
          onCreateContainerForStartSigningReady: _ctx.storeCreateContainerForStartSigning,
          onDisableChangingInformation: _ctx.handleDisableChangingInformation,
          class: "mb-4"
        }, null, 8, ["accountData", "setLoading", "addNewDevice", "onCreateContainerForStartSigningReady", "onDisableChangingInformation"]))
      : _createCommentVNode("", true),
    (_ctx.signupStep > 1 && !_ctx.changingInformationDisabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn btn-secondary me-2",
            disabled: _ctx.isLoading,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setStep(_ctx.signupStep - 1)))
          }, _toDisplayString(_ctx.$t("signup.navsteps.back")), 9, _hoisted_6),
          _createElementVNode("button", {
            class: "btn btn-primary",
            disabled: _ctx.isLoading,
            onClick: _cache[6] || (_cache[6] = ($event: any) => (
          _ctx.shownStep.step_name === _ctx.StepName.CONTRACT_REVIEW
            ? _ctx.createContainerForStartSigningFunction()
            : _ctx.setStep(_ctx.signupStep + 1)
        ))
          }, _toDisplayString(_ctx.shownStep.step_name === _ctx.StepName.CONTRACT_REVIEW
            ? _ctx.$t("signup.navsteps.to_signature_page")
            : _ctx.$t("signup.navsteps.next")), 9, _hoisted_7)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingOverlayComponent, { key: 8 }))
      : _createCommentVNode("", true),
    _createVNode(_component_AccountDataDebugComponent, {
      accountData: _ctx.accountData,
      class: "mb-4"
    }, null, 8, ["accountData"])
  ]))
}