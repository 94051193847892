// client/src/components/signup/se/grid-fields.ts

import { Composer } from "vue-i18n";
import { useValidators } from "@/composables/useValidators";
import type { GeneratorFieldConfig } from "@/components/signup/DynamicGeneratorField.vue";

export function getGridFields(t: Composer["t"]) {
  const { required } = useValidators(t);

  const gridOwnerFields: GeneratorFieldConfig[] = [
    {
      title: "grid_owner_name",
      model: "grid_owner_name",
      type: "string",
      validation: [required],
    },
    {
      title: "grid_owner_corporate_id",
      model: "grid_owner_corporate_id",
      type: "string",
      validation: [required],
    },
    {
      title: "grid_owner_contact_name",
      model: "grid_owner_contact_name",
      type: "string",
      validation: [required],
    },
    {
      title: "grid_owner_contact_telephone",
      model: "grid_owner_contact_telephone",
      type: "string",
      validation: [required],
    },
    {
      title: "grid_owner_contact_email",
      model: "grid_owner_contact_email",
      type: "string",
      validation: [required],
    },
  ];

  const gridOwnerAddressFields: GeneratorFieldConfig[] = [
    {
      title: "postal_address",
      model: "postal_address",
      type: "string",
      validation: [required],
    },
    {
      title: "postal_code",
      model: "postal_code",
      type: "string",
      validation: [required],
    },
    {
      title: "city",
      model: "city",
      type: "string",
      validation: [required],
    },
    {
      title: "co_address",
      model: "co_address",
      type: "string",
    },
    {
      title: "telephone",
      model: "telephone",
      type: "string",
    },
    {
      title: "email",
      model: "email",
      type: "string",
    },
  ];

  return {
    gridOwnerFields,
    gridOwnerAddressFields,
  };
}
