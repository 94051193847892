<!-- client/src/components/signup/se/GeneratorReviewComponent.vue -->

<template>
  <div class="generator-review card p-4 mb-3" novalidate>
    <!-- Basic Information -->
    <h4>{{ $t("signup.se.generator_review.basic_information") }}</h4>

    <!-- Production Units -->
    <div
      v-for="(unit, index) in localGenerator.application_information
        .new_production_unit_applications"
      :key="index"
      class="border rounded p-3 mb-3"
    >
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5>
          {{ $t("signup.se.generator_review.production_unit") }} #{{
            index + 1
          }}
        </h5>
        <button
          v-if="index === 1"
          @click="removeProductionUnit(index)"
          class="btn btn-outline-danger btn-sm"
        >
          {{ $t("common.remove") }}
        </button>
      </div>

      <DynamicGeneratorField
        v-for="field in productionUnitFields"
        :key="field.title"
        :field="field"
        v-model="unit[field.model]"
        translationPrefix="signup.se"
      />
    </div>

    <div v-if="canAddMoreUnits" class="mb-4">
      <button @click="addProductionUnit" class="btn btn-outline-primary">
        {{ $t("common.add_another_unit") }}
      </button>
    </div>

    <!-- Metering Information -->
    <h4 class="mt-4">
      {{ $t("signup.se.generator_review.metering_information") }}
    </h4>
    <DynamicGeneratorField
      v-for="field in goBasicFields"
      :key="field.title"
      :field="field"
      v-model="
        localGenerator.application_information.go_application[field.model]
      "
      translationPrefix="signup.se"
    />

    <!-- Investment Support Section -->
    <h4 class="mt-4">
      {{ $t("signup.se.generator_review.investment_support") }}
    </h4>
    <div class="row mb-2 g-2">
      <label class="col-sm-12 pb-0 col-form-label">
        {{ $t("signup.se.generator_review.investment_support_question") }}
      </label>
      <div class="col-sm-11">
        <div
          class="form-check"
          v-for="option in investmentSupportOptions"
          :key="option.value"
        >
          <input
            class="form-check-input"
            type="radio"
            :id="`investment-support-${option.value}`"
            :value="option.value"
            v-model="
              localGenerator.application_information.go_application
                .investment_support
            "
          />
          <label
            class="form-check-label"
            :for="`investment-support-${option.value}`"
          >
            {{ $t(option.label) }}
          </label>
        </div>
      </div>
    </div>

    <!-- Investment Support Details -->
    <template
      v-if="
        localGenerator.application_information.go_application
          .investment_support === 'Yes'
      "
    >
      <DynamicGeneratorField
        v-for="field in investmentFields"
        :key="field.title"
        :field="field"
        v-model="
          localGenerator.application_information.go_application[field.model]
        "
        translationPrefix="signup.se"
      />
    </template>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  computed,
  watch,
  type PropType,
  Ref,
} from "vue";
import type { Account } from "@/types";
import { useI18n } from "vue-i18n";
import DynamicGeneratorField from "@/components/signup/DynamicGeneratorField.vue";
import type { GeneratorFieldConfig } from "@/components/signup/DynamicGeneratorField.vue";
import { useValidators } from "@/composables/useValidators";
import {
  CorrectionFactor,
  getGeneratorFields,
  getGoBasicFields,
  InvestmentSupport,
  MeteringLocation,
} from "./generator-fields";

export default defineComponent({
  name: "GeneratorReviewComponent",
  components: {
    DynamicGeneratorField,
  },
  props: {
    generatorIndex: {
      type: Number,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    accountData: {
      type: Object as () => Account,
      required: true,
    },
  },
  emits: ["handleUpdateGenerator"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { productionUnitFields, investmentFields, investmentSupportOptions } =
      getGeneratorFields(t);

    const generator = computed(() => {
      return props.accountData.companies[props.companyIndex].generators[
        props.generatorIndex
      ];
    });

    const createEmptyUnit = () => ({
      device_name: "",
      device_power: 0,
      grid_contract_start_date: "",
      property_designation: "",
      postal_code: "",
      city: "",
      technology: null,
      estimated_annual_production: null,
    });

    // Initialize with default values
    const localGenerator = reactive({
      ...generator.value,
      application_information: {
        ...generator.value.application_information,
        new_production_unit_applications: [
          // Initialize with existing units or create a new one if none exist
          ...(generator.value.application_information
            ?.new_production_unit_applications || []),
          // If no units exist, create one empty unit
          ...(!generator.value.application_information
            ?.new_production_unit_applications?.length
            ? [createEmptyUnit()]
            : []),
        ],
        go_application: {
          metering_location: MeteringLocation.DIRECTLY_BEFORE_GRID_CONNECTION,
          registration_status: null,
          correction_factor: CorrectionFactor.STANDARD_VALUE,
          investment_support: InvestmentSupport.NO,
          investment_support_amount: null,
          investment_support_date: null,
          holding_percentage: 100.0,
          grid_metered_by_hour: false,
          grid_concession: false,
          ...generator.value.application_information?.go_application,
        },
      },
    });

    const goBasicFields = getGoBasicFields(
      t,
      localGenerator.application_information.go_application
    );

    const canAddMoreUnits = computed(() => {
      return (
        localGenerator.application_information.new_production_unit_applications
          .length < 2
      );
    });

    const addProductionUnit = () => {
      if (canAddMoreUnits.value) {
        localGenerator.application_information.new_production_unit_applications.push(
          createEmptyUnit()
        );
      }
    };

    const removeProductionUnit = (index: number) => {
      if (index > 0) {
        localGenerator.application_information.new_production_unit_applications.splice(
          index,
          1
        );
      }
    };

    watch(
      localGenerator,
      (newValue) => {
        emit("handleUpdateGenerator", newValue);
      },
      { deep: true }
    );

    return {
      localGenerator,
      productionUnitFields,
      goBasicFields,
      investmentFields,
      investmentSupportOptions,
      canAddMoreUnits,
      addProductionUnit,
      removeProductionUnit,
    };
  },
});
</script>

<style scoped lang="scss">
.generator-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
    padding: 0;
  }

  .form-check {
    margin-bottom: 0.5rem;
  }

  h4 {
    margin-bottom: 1rem;
  }

  .border {
    border-color: #dee2e6 !important;
  }

  .production-unit {
    background-color: #f8f9fa;
  }
}

@media (max-width: 575.98px) {
  .generator-review {
    .col-form-label {
      margin-top: 1rem;
    }
  }
  h4 {
    font-size: 1.2rem;
  }
}
</style>
