<template>
  <div id="AdminTransactions">
    <div class="container mt-5 mb-5">
      <button
        v-if="pendingNotificationEmailCount > 0"
        class="btn btn-sm btn-outline-primary mb-5"
        @click="postSendNotificationEmails"
      >
        <font-awesome-icon icon="envelope" />
        Send transaction notification to
        {{ pendingNotificationEmailCount }} companies
      </button>
      <h2>Transaction Details</h2>
      <div class="mb-3">
        <label for="counterpartyName" class="form-label"
          >Counterparty Name</label
        >
        <input
          type="text"
          class="form-control"
          id="counterpartyName"
          v-model="counterpartyName"
        />
      </div>
      <div class="mb-3">
        <label for="counterpartyCountry" class="form-label"
          >Counterparty Country</label
        >
        <input
          type="text"
          class="form-control"
          id="counterpartyCountry"
          v-model="counterpartyCountry"
          maxlength="2"
        />
      </div>
      <div class="mb-3">
        <label for="transactionDate" class="form-label">Transaction Date</label>
        <input
          type="date"
          class="form-control"
          id="transactionDate"
          v-model="transactionDate"
        />
      </div>
      <div class="mb-3">
        <label for="unitPrice" class="form-label">Unit Price</label>
        <input
          type="number"
          class="form-control"
          id="unitPrice"
          v-model.number="unitPrice"
          step="0.01"
        />
      </div>
      <div class="mb-3">
        <label for="offersTaken" class="form-label">Offers Taken</label>
        <input
          type="number"
          class="form-control"
          id="offersTaken"
          v-model.number="offersTaken"
          step="1"
        />
      </div>
      <div class="mb-3">
        <label for="minPrice" class="form-label">Min Price</label>
        <input
          type="number"
          class="form-control"
          id="minPrice"
          v-model.number="minPrice"
          step="1"
        />
      </div>
      <div class="mb-3">
        <label for="transactionLink" class="form-label"
          >Transaction Link
          <a
            href="https://drive.google.com/drive/u/0/folders/1vIStNhb0FgNpIQTz5RhTSroaodOOtuj0"
            target="_blank"
            >please link specific folder</a
          ></label
        >
        <input
          type="text"
          class="form-control"
          id="transactionLink"
          v-model="transactionLink"
        />
      </div>
      <h3 class="mt-4 mb-3">Sellable certificates</h3>
      <!-- Radio button for "All" option -->
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          id="allVintages"
          type="radio"
          name="vintageOptions"
          :value="NaN"
          v-model="selectedVintage"
        />
        <label class="form-check-label" for="allVintages">All</label>
      </div>
      <!-- Dynamically generated radio buttons for available vintages -->
      <div
        class="form-check form-check-inline"
        v-for="(vintage, index) in availableVintages"
        :key="index"
      >
        <input
          class="form-check-input"
          :id="`vintage${vintage}`"
          type="radio"
          name="vintageOptions"
          :value="vintage"
          v-model="selectedVintage"
        />
        <label class="form-check-label" :for="`vintage${vintage}`">{{
          vintage
        }}</label>
      </div>
      <!-- Checkbox for excluding expiring supply -->
      <div class="form-check mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          id="excludeExpiringSupply"
          v-model="excludeExpiringSupply"
        />
        <label class="form-check-label" for="excludeExpiringSupply">
          Exclude expiring supply
        </label>
      </div>
      <div class="mt-4">
        <div class="row fw-bold">
          <div class="col-6">Production Month</div>
          <div class="col-6">Total Certificates</div>
        </div>
        <div
          class="row"
          v-for="(summaryVal, productionMonth) in certificatesSummary"
          :key="productionMonth"
        >
          <div class="col-6">{{ formatMonthYear(productionMonth) }}</div>
          <div class="col-6">{{ summaryVal.count }}</div>
        </div>
        <hr class="m-1" />
        <div class="row fw-bold">
          <div class="col-6">TOTAL</div>
          <div class="col-6">{{ totalCertificates }}</div>
        </div>
      </div>
      <button
        @click="postSalesTransaction"
        class="btn btn-lg btn-primary mt-5 w-100"
      >
        Record Transaction
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, onMounted, inject } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { Certificate } from "@/types";

export default {
  name: "AdminTransactionsView",
  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const globalSuccessHandler = inject("globalSuccessHandler") as (
      tite: string,
      message: string
    ) => void;
    const pendingNotificationEmailCount = ref(0);
    const counterpartyName = ref("");
    const counterpartyCountry = ref("");
    const transactionDate = ref("");
    const transactionLink = ref("");
    const unitPrice = ref(0);
    const offersTaken = ref(0);
    const minPrice = ref(0);
    const sellableCertificates = ref<Certificate[]>([]);
    const availableVintages = computed(() => {
      const years = new Set<number>();
      sellableCertificates.value.forEach((certificate) => {
        const year = new Date(certificate.production_month).getFullYear();
        years.add(year);
      });
      return Array.from(years).sort((a, b) => a - b); // Convert the Set to an array and sort it
    });
    // Default selected vintage is null, which corresponds to the "All" option
    const selectedVintage = ref(NaN);
    const excludeExpiringSupply = ref(true); // State for the "Exclude expiring supply" checkbox

    const certificatesSummary = computed(() => {
      const summary: Record<string, { count: number; ids: number[] }> = {};
      const cutoffDate = new Date();
      cutoffDate.setFullYear(cutoffDate.getFullYear() - 1); // Subtract 1 year
      cutoffDate.setMonth(cutoffDate.getMonth() - 1); // Subtract 1 month to enable last month sales

      sellableCertificates.value.forEach((certificate) => {
        const productionDate = new Date(certificate.production_month);
        const year = productionDate.getFullYear();

        if (
          (excludeExpiringSupply.value && productionDate < cutoffDate) ||
          (selectedVintage.value && selectedVintage.value !== year)
        ) {
          return;
        }

        const monthKey = productionDate.toISOString().slice(0, 7); // Convert to YYYY-MM
        if (!summary[monthKey]) {
          summary[monthKey] = { count: 0, ids: [] };
        }
        summary[monthKey].count += certificate.count;
        summary[monthKey].ids.push(certificate.id);
      });

      // Optionally, sort by month key if needed
      return summary;
    });

    // Computed property to calculate the total number of certificates
    const totalCertificates = computed(() => {
      return Object.values(certificatesSummary.value).reduce(
        (total, { count }) => total + count,
        0
      );
    });

    const formatMonthYear = (value: string): string => {
      const date = new Date(value);
      return date.toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      });
    };

    const fetchSellableCertificates = async () => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/sellable-certificates"
        );
        if (response.status === 200 && response.data) {
          sellableCertificates.value = response.data.certificates;
        } else {
          throw new Error("Error fetching sellable certificates.");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    const fetchPendingNotificationEmailCount = async () => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/pending-notification-email-count"
        );
        if (response.status === 200 && response.data) {
          pendingNotificationEmailCount.value = response.data.count;
        } else {
          throw new Error("Error fetching pending notification emails count.");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    const fetchData = async () => {
      fetchSellableCertificates();
      fetchPendingNotificationEmailCount();
    };

    const postSalesTransaction = async () => {
      try {
        const ids = Object.values(certificatesSummary.value).flatMap(
          (summary) => summary.ids
        );
        const response = await goSolidApi.post(
          "/api/admin/register-transaction",
          {
            transaction_at: transactionDate.value,
            unit_price: unitPrice.value.toFixed(2),
            amount: totalCertificates.value,
            counterparty_name: counterpartyName.value,
            counterparty_country: counterpartyCountry.value,
            description: `v${
              selectedVintage.value || availableVintages.value.join("-")
            }`,
            offers_taken: offersTaken.value.toFixed(0),
            min_price: minPrice.value.toFixed(2),
            transaction_link: transactionLink.value,
            certificate_ids: ids,
          }
        );
        if (response.status === 200 && response.data) {
          globalSuccessHandler(
            "Transaction recorded",
            "May god save your soul, you've done a good job today"
          );
          fetchData();
        } else {
          throw new Error("Error posting transaction.");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    const postSendNotificationEmails = async () => {
      try {
        const response = await goSolidApi.post(
          "/api/admin/send-notification-emails"
        );
        if (response.status === 200 && response.data) {
          globalSuccessHandler(
            "Notification emails sent",
            "The notification emails have been sent successfully"
          );
          fetchData();
        } else {
          throw new Error("Error sending notification emails.");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    onMounted(async () => {
      fetchData();
    });

    return {
      counterpartyName,
      counterpartyCountry,
      transactionDate,
      unitPrice,
      offersTaken,
      minPrice,
      availableVintages,
      selectedVintage,
      certificatesSummary,
      formatMonthYear,
      totalCertificates,
      excludeExpiringSupply,
      postSalesTransaction,
      pendingNotificationEmailCount,
      postSendNotificationEmails,
      transactionLink,
    };
  },
};
</script>

<style lang="scss" scoped>
#AdminTransactions {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}
.container {
  max-width: 600px;
}
</style>
