// src/composables/useGoSolidApi.ts

import axios from "axios";
import { authStore } from "@/store/authStore";

axios.defaults.withCredentials = true;
const goSolidApi = axios.create();

if (
  process.env.NODE_ENV === "production" &&
  window.location.hostname === "app.soldera.org"
) {
  goSolidApi.defaults.baseURL = "https://api.soldera.org";
} else if (
  process.env.NODE_ENV === "production" &&
  window.location.hostname === "proov.soldera.org"
) {
  goSolidApi.defaults.baseURL = "https://api.proov.soldera.org";
} else {
  goSolidApi.defaults.baseURL = "http://127.0.0.1:5000";
}

// Request interceptor to add the auth token
goSolidApi.interceptors.request.use(
  async (config) => {
    const token = await authStore.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function getFilenameFromContentDispositionHeader(
  contentDisposition: string
): string | undefined {
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
  }

  return undefined;
}

export { goSolidApi, getFilenameFromContentDispositionHeader };
