export const SUPPORTED_COUNTRIES = ["EE", "LV", "LT", "SE"];
export const SUPPORTED_COUNTRIES_WITH_LABELS = [
  { value: "LV", label: "Latvia" },
  { value: "EE", label: "Estonia" },
  { value: "LT", label: "Lithuania" },
  { value: "SE", label: "Sweden" },
];

export const LANGUAGE_TO_COUNTRY = {
  et: "EE",
  lv: "LV",
  lt: "LT",
  sv: "SE",
} as const;

export const ALL_COUNTRIES = [
  { value: "BE", label: "België/Belgique" },
  { value: "BG", label: "България" },
  { value: "CY", label: "Κύπρος" },
  { value: "CZ", label: "Česko" },
  { value: "DK", label: "Danmark" },
  { value: "DE", label: "Deutschland" },
  { value: "EE", label: "Eesti" },
  { value: "GR", label: "Ελλάδα" },
  { value: "ES", label: "España" },
  { value: "FR", label: "France" },
  { value: "HR", label: "Hrvatska" },
  { value: "IE", label: "Éire" },
  { value: "IT", label: "Italia" },
  { value: "LV", label: "Latvija" },
  { value: "LT", label: "Lietuva" },
  { value: "LU", label: "Luxembourg" },
  { value: "HU", label: "Magyarország" },
  { value: "MT", label: "Malta" },
  { value: "NL", label: "Nederland" },
  { value: "AT", label: "Österreich" },
  { value: "PL", label: "Polska" },
  { value: "PT", label: "Portugal" },
  { value: "RO", label: "România" },
  { value: "SK", label: "Slovensko" },
  { value: "SI", label: "Slovenija" },
  { value: "FI", label: "Suomi" },
  { value: "SE", label: "Sverige" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "XX", label: "Other" },
];

export const CHART_COLORS = {
  wind: {
    background: "rgba(54, 162, 235, 0.8)",
    border: "rgba(54, 162, 235, 1)",
  },
  solar: {
    background: "rgba(255, 159, 64, 0.8)",
    border: "rgba(255, 159, 64, 1)",
  },
  hydro: {
    background: "rgba(54, 235, 162, 0.8)",
    border: "rgba(54, 235, 162, 1)",
  },
  sold: {
    background: "rgba(75, 192, 192, 0.8)",
    border: "rgba(75, 192, 192, 1)",
  },
  forSale: {
    background: "rgba(255, 206, 86, 0.8)",
    border: "rgba(255, 206, 86, 1)",
  },
  notForSale: {
    background: "rgba(153, 102, 255, 0.8)",
    border: "rgba(153, 102, 255, 1)",
  },
};
