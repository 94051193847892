<!-- client/src/components/signup/se/SEContractReviewComponent.vue -->

<template>
  <ContractReviewDisplayComponent
    :accountData="accountData"
    :contractsToShow="contracts"
    :setLoading="setLoading"
    :signerFields="signerFields"
    :companyFields="companyFields"
    :generatorFields="generatorFields"
    :secondUnitFields="secondUnitFields"
    :goApplicationFields="goApplicationFields"
    :contractState="contractState"
    :hasGenerator="hasGenerator"
    @createContainerForStartSigningReady="
      $emit('createContainerForStartSigningReady', $event)
    "
  />
</template>

<script lang="ts">
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { Account, Document } from "@/types";
import { useRoute } from "vue-router";
import { defineComponent, reactive, computed, PropType } from "vue";
import ContractReviewDisplayComponent from "@/components/signup/ContractReviewDisplayComponent.vue";
import { useI18n } from "vue-i18n";
import { getGeneratorFields, SeGeneratorType } from "./generator-fields";
import { formatDate } from "@/composables/formatUtils";
import type { GeneratorFieldConfig } from "@/components/signup/DynamicGeneratorField.vue";

export default defineComponent({
  name: "SEContractReviewComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    setLoading: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    },
    addNewDevice: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ContractReviewDisplayComponent,
  },
  emits: ["createContainerForStartSigningReady"],
  setup(props) {
    const { t } = useI18n();
    const isAdmin = !!props.accountData.admin_account_id;
    const signerFields = reactive([
      { label: t("account.name"), value: props.accountData.name },
      { label: t("account.email"), value: props.accountData.email },
      {
        label: t("account.phone_number"),
        value: props.accountData.phone_number,
      },
    ]);
    const { nullableSelectedGenerator } = useGeneratorFinder(props.accountData);
    const { companyIndex } = useCompanyFinder(props.accountData);
    const selectedCompany = computed(
      () => props.accountData.companies[companyIndex.value]
    );
    const companyFields = reactive([
      { label: t("company.name"), value: selectedCompany.value.name },
      {
        label: t("company.registry_code"),
        value: selectedCompany.value.registry_code,
      },
      {
        label: t("company.address"),
        value: nullableSelectedGenerator.value.generator
          ?.application_information?.full_company_address?.postal_address
          ? `${nullableSelectedGenerator.value.generator?.application_information?.full_company_address?.postal_address}, ${nullableSelectedGenerator.value.generator?.application_information?.full_company_address?.postal_code} ${nullableSelectedGenerator.value.generator?.application_information?.full_company_address?.city}`
          : selectedCompany.value.address,
      },
    ]);
    const hasGenerator = computed(() => {
      return nullableSelectedGenerator.value.generator !== null;
    });
    const { productionUnitFields } = getGeneratorFields(t);

    let generatorFields: any = [];
    let secondUnitFields: any = [];
    let goApplicationFields: any = [];
    if (nullableSelectedGenerator.value.generator) {
      // Production unit fields - Basic Info
      generatorFields = productionUnitFields.map(
        (field: GeneratorFieldConfig) => ({
          label: t(`signup.se.generator_review.${field.title}.title`),
          value:
            nullableSelectedGenerator.value.generator?.application_information
              ?.new_production_unit_applications?.[0]?.[field.model],
        })
      );

      // Add technology field with human-readable value
      if (
        nullableSelectedGenerator.value.generator?.application_information
          ?.new_production_unit_applications?.[0]?.technology
      ) {
        const technologyField = {
          label: t("signup.se.generator_review.technology.title"),
          value: t(
            `signup.se.generator_review.technology.options.${nullableSelectedGenerator.value.generator?.application_information?.new_production_unit_applications?.[0]?.technology}`
          ),
        };
        generatorFields.push(technologyField);
      }

      const goApplication =
        nullableSelectedGenerator.value.generator?.application_information
          ?.go_application;
      if (goApplication) {
        // Grid Connection Info
        if (goApplication.grid_metered_by_hour !== undefined) {
          goApplicationFields.push({
            label: t("signup.se.generator_review.grid_metered_by_hour.title"),
            value: goApplication.grid_metered_by_hour
              ? t("common.yes")
              : t("common.no"),
          });
        }

        if (goApplication.grid_concession !== undefined) {
          goApplicationFields.push({
            label: t("signup.se.generator_review.grid_concession.title"),
            value: goApplication.grid_concession
              ? t("common.yes")
              : t("common.no"),
          });
        }

        // Metering Info
        if (goApplication.metering_location) {
          goApplicationFields.push({
            label: t("signup.se.generator_review.metering_location.title"),
            value: t(
              `signup.se.generator_review.metering_location.options.${goApplication.metering_location}`
            ),
          });
        }

        // Registration Info
        if (goApplication.registration_status) {
          goApplicationFields.push({
            label: t("signup.se.generator_review.registration_status.title"),
            value: t(
              `signup.se.generator_review.registration_status.options.${goApplication.registration_status}`
            ),
          });
        }

        // Ownership Info
        if (goApplication.holding_percentage) {
          goApplicationFields.push({
            label: t("signup.se.generator_review.holding_percentage.title"),
            value: `${goApplication.holding_percentage}%`,
          });
        }

        // Investment Support Info
        if (goApplication.investment_support) {
          goApplicationFields.push({
            label: t("signup.se.generator_review.investment_support_question"),
            value: t(
              `signup.se.generator_review.investment_support_options.${goApplication.investment_support.toLowerCase()}`
            ),
          });

          if (goApplication.investment_support === "Yes") {
            if (goApplication.investment_support_amount) {
              goApplicationFields.push({
                label: t(
                  "signup.se.generator_review.investment_support_amount.title"
                ),
                value: `${goApplication.investment_support_amount} €`,
              });
            }
            if (goApplication.investment_support_date) {
              goApplicationFields.push({
                label: t(
                  "signup.se.generator_review.investment_support_date.title"
                ),
                value: formatDate(goApplication.investment_support_date),
              });
            }
          }
        }
      }

      // Handle second unit if exists
      if (
        nullableSelectedGenerator.value.generator?.application_information
          ?.new_production_unit_applications?.length > 1
      ) {
        secondUnitFields = productionUnitFields.map(
          (field: GeneratorFieldConfig) => ({
            label: t(`signup.se.generator_review.${field.title}.title`),
            value:
              nullableSelectedGenerator.value.generator?.application_information
                ?.new_production_unit_applications?.[1]?.[field.model],
          })
        );
      }

      // Add technology field for second unit if exists
      if (
        nullableSelectedGenerator.value.generator?.application_information
          ?.new_production_unit_applications?.[1]?.technology
      ) {
        const technologyField = {
          label: t("signup.se.generator_review.technology.title"),
          value: t(
            `signup.se.generator_review.technology.options.${nullableSelectedGenerator.value.generator?.application_information?.new_production_unit_applications?.[1]?.technology}`
          ),
        };
        secondUnitFields.push(technologyField);
      }
    }

    const route = useRoute();

    const addAuthorizationAgreement = computed(() => {
      return route.query.addAuthorizationAgreement === "true";
    });
    const contractState = computed(() => {
      if (props.addNewDevice) {
        return 2;
      }
      return 1;
    });

    const hasSignedOptionalContract = (selectedContract: string) => {
      return selectedCompany.value?.documents.some(
        (document: Document) =>
          document.status === "completed" && // The contract needs to be signed
          document.contract_keys.includes(selectedContract) // and if it has our contract key, we want to return true
      );
    };

    const shouldSignOptionalContractIfOptional = (
      optionalContractKey: string | null
    ): boolean => {
      return (
        !optionalContractKey || !hasSignedOptionalContract(optionalContractKey)
      );
    };

    const includeDocument = (
      states: number[],
      optionalContractKey: string | null = null
    ) =>
      computed(() => {
        return (
          states.includes(contractState.value) &&
          shouldSignOptionalContractIfOptional(optionalContractKey)
        );
      });

    // https://www.notion.so/solidworld/Sweden-13b259ad6d9980b99ab0ef0f79ae188f
    const contracts = reactive([
      [
        {
          id: 1,
          key: "se_application_for_go",
          contract_title: t(
            "signup.contract_review_component.se.se_application_for_go_title"
          ),
          contract_description: t(
            "signup.contract_review_component.se.se_application_for_go_text"
          ),
          include: includeDocument([2]).value,
          can_include: true,
        },
        {
          id: 2,
          key: "se_appendix_production_units",
          contract_title: t(
            "signup.contract_review_component.se.se_appendix_production_units_title"
          ),
          contract_description: t(
            "signup.contract_review_component.se.se_appendix_production_units_text"
          ),
          include: includeDocument([2]).value,
          can_include: true,
        },
        {
          id: 3,
          key: "se_application_for_eecs_go",
          contract_title: t(
            "signup.contract_review_component.se.se_application_for_eecs_go_title"
          ),
          contract_description: t(
            "signup.contract_review_component.se.se_application_for_eecs_go_text"
          ),
          include: includeDocument([2]).value,
          can_include: true,
        },
        {
          id: 4,
          key: "se_application_for_special_account",
          contract_title: t(
            "signup.contract_review_component.se.se_application_for_special_account_title"
          ),
          contract_description: t(
            "signup.contract_review_component.se.se_application_for_special_account_text"
          ),
          include: includeDocument([2]).value,
          can_include: true,
        },
        {
          id: 5,
          key: "se_application_for_cesar_access",
          contract_title: t(
            "signup.contract_review_component.se.se_application_for_cesar_access_title"
          ),
          contract_description: t(
            "signup.contract_review_component.se.se_application_for_cesar_access_text"
          ),
          include: includeDocument([2]).value,
          can_include: true,
        },
        {
          id: 6,
          key: "se_soldera_membership_agreement",
          contract_title: t(
            "signup.contract_review_component.se.se_soldera_membership_agreement_title"
          ),
          contract_description: t(
            "signup.contract_review_component.se.se_soldera_membership_agreement_text"
          ),
          include: includeDocument([1, 2]).value,
          can_include: true,
        },
        {
          id: 7,
          key: "se_authorization_application",
          contract_title: t(
            "signup.contract_review_component.se.se_account_authorization_form_title"
          ),
          contract_description: t(
            "signup.contract_review_component.se.se_account_authorization_form_text"
          ),
          include: includeDocument([1, 2]).value,
          can_include: true,
        },
      ],
    ]);

    return {
      contracts,
      signerFields,
      companyFields,
      generatorFields,
      secondUnitFields,
      goApplicationFields,
      contractState,
      hasGenerator,
      isAdmin,
    };
  },
});
</script>

<style scoped lang="scss"></style>
