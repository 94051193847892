import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6416035c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "generator-review card p-4 mb-3",
  novalidate: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicGeneratorField = _resolveComponent("DynamicGeneratorField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.$t("signup.se.generator_review.basic_information")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridOwnerFields, (field) => {
      return (_openBlock(), _createBlock(_component_DynamicGeneratorField, {
        key: field.title,
        field: field,
        modelValue: 
        _ctx.localGenerator.application_information.go_application[field.model]
      ,
        "onUpdate:modelValue": ($event: any) => ((
        _ctx.localGenerator.application_information.go_application[field.model]
      ) = $event),
        translationPrefix: "signup.se"
      }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridOwnerAddressFields, (field) => {
      return (_openBlock(), _createBlock(_component_DynamicGeneratorField, {
        key: field.title,
        field: field,
        modelValue: 
        _ctx.localGenerator.application_information.go_application
          .grid_owner_address[field.model]
      ,
        "onUpdate:modelValue": ($event: any) => ((
        _ctx.localGenerator.application_information.go_application
          .grid_owner_address[field.model]
      ) = $event),
        translationPrefix: "signup.se"
      }, null, 8, ["field", "modelValue", "onUpdate:modelValue"]))
    }), 128))
  ]))
}