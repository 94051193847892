<!-- client/src/views/admin/AccountsView.vue -->

<template>
  <div id="AdminAccounts" class="container">
    <div class="row mb-3 mt-4">
      <div class="col">
        <h1>Accounts</h1>
      </div>
      <div class="col-auto d-flex gap-3 align-items-center">
        <select
          class="form-select"
          v-model="selectedCountry"
          @change="filterAccounts"
        >
          <option value="">All Countries</option>
          <option
            v-for="country in availableCountries"
            :key="country"
            :value="country"
          >
            {{ country }}
          </option>
        </select>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="showOnlyRegistered"
            @change="filterAccounts"
            id="registeredFilter"
          />
          <label class="form-check-label text-nowrap" for="registeredFilter">
            Show only registered
          </label>
        </div>
        <button
          class="btn btn-secondary text-nowrap"
          @click="stopAccountManaging()"
        >
          Reset Managing
        </button>
      </div>
    </div>
    <div class="table-container table-responsive scrollbar">
      <table id="account-table" class="table table-hover align-middle p-2">
        <thead>
          <tr>
            <th>ID</th>
            <th>Actions</th>
            <th>Account</th>
            <th>
              Company - ID - Registry code - Registry ID - Has registered -
              Registry devices
            </th>
            <th>Meta</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="account in accounts"
            :key="account.id"
            :class="{
              'table-active': account.id === Number(managingAccount.id || null),
            }"
          >
            <td>{{ account.id }}</td>
            <td>
              <button
                class="btn btn-primary btn-sm"
                v-if="account.id"
                @click="onclick(account.id)"
              >
                Manage
              </button>
            </td>
            <td class="col-2">
              {{ account.email }}
              <span
                v-if="account.internal_role === 'super_admin'"
                class="badge rounded-pill bg-primary text-white"
                >Admin
              </span>
              <br />
              {{ account.name }} <br />
              {{ account.phone_number }}
            </td>
            <td class="table-company-list">
              <div
                v-for="status in getCompanyStatuses(account)"
                :key="`${account.id}-${status.concat()}`"
                class="company-status-row p-2 mb-1 rounded"
                :class="{
                  'bg-danger bg-opacity-25':
                    status.includes('has_registered: true') &&
                    (!status.includes('registry_id:') ||
                      status.includes('registry_devices: 0')),
                  'bg-warning bg-opacity-25':
                    status.includes('has_registered: true') &&
                    status.includes('registry_id:') &&
                    status.includes('registry_devices: 0'),
                  'bg-success bg-opacity-25':
                    status.includes('has_registered: true') &&
                    status.includes('registry_id:') &&
                    !status.includes('registry_devices: 0'),
                }"
              >
                {{ status }}
              </div>
            </td>
            <td>
              <div v-if="fetch_tracking(account)">
                <span class="badge badge-phoenix badge-phoenix-secondary"
                  >Source
                </span>
                <br />
                {{ fetch_tracking(account) }} <br />
              </div>
              <div v-if="account.comment">
                <span class="badge badge-phoenix badge-phoenix-info"
                  >Comment
                </span>
                <br />
                {{ account.comment }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import type { Account, Company, Generator, RegistryDevice } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useManageAccount } from "@/composables/admin/useManageAccount";

interface AdminAccountProjection {
  companies: (Company & AdminCompanyProjection)[];
}

interface AdminCompanyProjection {
  registry_devices: (RegistryDevice & AdminRegistryDeviceProjection)[];
}

interface AdminRegistryDeviceProjection {
  total_cert_count: number;
}
export default defineComponent({
  methods: {
    fetch_tracking(account: Account): string {
      if (account.conversion_tracking?.referrer_id) {
        return String(account.conversion_tracking?.referrer_id);
      } else if (account.conversion_tracking?.utm_source) {
        return (
          account.conversion_tracking?.utm_source +
          " " +
          account.conversion_tracking?.utm_campaign
        );
      } else {
        return "";
      }
    },
    calculateSignedDevices(account: Account): number {
      let signedDevices = 0;

      account.companies?.forEach((company: Company) => {
        company.generators?.forEach((generator: Generator) => {
          if (generator.has_registered) {
            signedDevices += 1;
          }
        });
      });

      return signedDevices;
    },
    calculateRegistryDevices(account: Account): number {
      let registryDevices = 0;

      account.companies?.forEach((company: Company) => {
        company.registry_devices?.forEach(() => {
          registryDevices += 1;
        });
      });

      return registryDevices;
    },
    calculateCertificates(account: AdminAccountProjection): number {
      let certificates = 0;

      account.companies?.forEach((company: AdminCompanyProjection) => {
        company.registry_devices?.forEach((registryDevice) => {
          certificates += registryDevice.total_cert_count;
        });
      });

      return certificates;
    },
    isNotTestAccount(account: Account): boolean {
      return account.internal_role !== "super_admin";
    },
    devicesBackgroundColor(account: Account): string {
      if (
        (this.calculateSignedDevices(account) > 0 ||
          this.calculateRegistryDevices(account) > 0) &&
        this.isNotTestAccount(account)
      ) {
        if (
          this.calculateRegistryDevices(account) <
          this.calculateSignedDevices(account)
        ) {
          return "bg-warning";
        } else {
          return "bg-success";
        }
      } else {
        return "";
      }
    },
    calculateKW(account: Account): string {
      let signedDevicesKw = 0;
      let registryDevicesKw = 0;
      account.companies?.forEach((company: Company) => {
        company.generators?.forEach((generator: Generator) => {
          if (!generator.device_power) return;
          if (generator.has_registered) {
            signedDevicesKw += generator.device_power;
          }
        });
        company.registry_devices?.forEach((registryDevice) => {
          registryDevicesKw += registryDevice.device_power;
        });
      });
      return `${signedDevicesKw.toFixed(0)} / ${registryDevicesKw.toFixed(0)}`;
    },
    getCompanyStatuses(account: Account): string[] {
      return account.companies.map(
        (company) =>
          company.name +
          " - " +
          company.id +
          " - " +
          company.registry_code +
          " - has_registered: " +
          company.has_registered +
          " - registry_id: " +
          (company.registry_id || "none") +
          " - registry_devices: " +
          company.registry_devices.length
      );
    },
  },

  setup(_, { emit }) {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const accounts = ref<(Account & AdminAccountProjection)[]>([]);
    const filteredAccounts = ref<(Account & AdminAccountProjection)[]>([]);
    const selectedCountry = ref(
      localStorage.getItem("admin_accounts_country") || ""
    );
    const showOnlyRegistered = ref(
      localStorage.getItem("admin_accounts_show_registered") === "true"
    );
    const availableCountries = ref<string[]>([]);
    const router = useRouter();
    const { manageAccount, managingAccount, resetManagingAccount } =
      useManageAccount();

    const updateAvailableCountries = (
      accounts: (Account & AdminAccountProjection)[]
    ) => {
      const countries = new Set<string>();
      accounts.forEach((account) => {
        account.companies.forEach((company) => {
          if (company.country) {
            countries.add(company.country);
          }
        });
      });
      availableCountries.value = Array.from(countries).sort();
    };

    const filterAccounts = () => {
      localStorage.setItem("admin_accounts_country", selectedCountry.value);
      localStorage.setItem(
        "admin_accounts_show_registered",
        showOnlyRegistered.value.toString()
      );

      filteredAccounts.value = accounts.value.filter((account) => {
        const matchesCountry =
          !selectedCountry.value ||
          account.companies.some(
            (company) => company.country === selectedCountry.value
          );

        const matchesRegistration =
          !showOnlyRegistered.value ||
          account.companies.some((company) => company.has_registered === true);

        return matchesCountry && matchesRegistration;
      });
    };

    const fetchAccounts = async () => {
      try {
        const response = await goSolidApi.get("/api/admin/accounts");
        if (response.status === 200 && response.data) {
          accounts.value = response.data;
          updateAvailableCountries(accounts.value);
          filterAccounts();
        } else {
          throw new Error("Error fetching accounts");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    onMounted(fetchAccounts);

    const onclick = (id: number) => {
      manageAccount(id, onClickSuccess);
    };

    const onClickSuccess = () => {
      emit("managing-account-updated");
      router.push({ name: "Root" });
    };

    const stopAccountManaging = () => {
      resetManagingAccount(onResetSuccess);
      router.push({ name: "Root" });
    };

    const onResetSuccess = () => {
      emit("managing-account-updated");
    };

    return {
      accounts: filteredAccounts,
      onclick,
      manageAccount,
      stopAccountManaging,
      managingAccount,
      selectedCountry,
      showOnlyRegistered,
      availableCountries,
      filterAccounts,
    };
  },
});
</script>

<style scoped>
#AdminAccounts {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}

.table-container {
  border-radius: 1rem;
  border: 1px solid var(--phoenix-gray-200);
  .table > tbody > tr > td:first-child {
    padding-left: 1rem;
  }
}

#account-table {
  margin-bottom: 0;
  table-layout: fixed;
  width: 100%;

  .table-active {
    background-color: var(--phoenix-warning-bg-subtle);
    td {
      background-color: #fff3cd;
      box-shadow: none;
    }
  }

  th,
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th:nth-child(1) {
    width: 5%;
  } /* ID */
  th:nth-child(2) {
    width: 10%;
  } /* Actions */
  th:nth-child(3) {
    width: 20%;
  } /* Account */
  th:nth-child(4) {
    width: 45%;
  } /* Company list */
  th:nth-child(5) {
    width: 20%;
  } /* Meta */

  .table-company-list {
    word-wrap: break-word;
    white-space: normal;
    padding: 0.5rem;

    .company-status-row {
      word-break: break-word;
      font-size: 0.9rem;
      transition: all 0.2s ease;

      &:hover {
        transform: translateX(5px);
      }
    }
  }
}

@media (max-width: 1200px) {
  #account-table {
    th:nth-child(1) {
      width: 8%;
    }
    th:nth-child(2) {
      width: 12%;
    }
    th:nth-child(3) {
      width: 25%;
    }
    th:nth-child(4) {
      width: 35%;
    }
    th:nth-child(5) {
      width: 20%;
    }
  }
}

@media (max-width: 768px) {
  #account-table {
    th:nth-child(1) {
      width: 10%;
    }
    th:nth-child(2) {
      width: 15%;
    }
    th:nth-child(3) {
      width: 30%;
    }
    th:nth-child(4) {
      width: 30%;
    }
    th:nth-child(5) {
      width: 15%;
    }
  }
}

.form-select {
  min-width: 150px;
}
</style>
