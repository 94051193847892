<!-- client/src/components/dashboard/RegistryDevicesSection.vue -->
<template>
  <SlottedContainerComponent
    :buttonText="$t('common.read_more_button')"
    class="mb-2"
    :containerClass="`bg-white px-3 border-translucent`"
    :collapsed="filteredRegistryDevices.length > 10"
    v-if="filteredRegistryDevices && filteredRegistryDevices.length > 0"
  >
    <template #title>
      <div class="flex my-2">
        <h4>
          {{ $t("dashboard.devices_in_registry") }}
        </h4>
        <p class="my-1">
          {{ $t("dashboard.devices") }}: {{ filteredRegistryDevices.length }}
        </p>
        <div class="my-1" v-if="company.country === 'LT' && reportingEnabled">
          <hr />
          <h5>{{ $t("dashboard.automaticReporting.title") }}</h5>
          <!-- Automatic reporting status display -->
          <div>
            {{ $t("common.status") }}
            <span
              v-if="isLoading"
              class="badge badge-phoenix badge-phoenix-secondary"
            >
              <FontAwesomeIcon icon="spinner" spin />
              {{ $t("common.loading") }}
            </span>
            <span
              v-else-if="
                declarationsAutomationStatus ===
                DeclarationAutomationStatus.Active
              "
              class="badge badge-phoenix badge-phoenix-success"
            >
              <FontAwesomeIcon icon="check" />
              {{ $t("common.active") }}
            </span>
            <span
              v-else-if="
                declarationsAutomationStatus ===
                DeclarationAutomationStatus.RequiresAction
              "
              class="badge badge-phoenix badge-phoenix-warning"
            >
              <FontAwesomeIcon icon="hand" />
              {{ $t("dashboard.automaticReporting.requiresAction") }}
            </span>
            <span v-else class="badge badge-phoenix badge-phoenix-warning">
              <FontAwesomeIcon icon="clock" />
              {{ $t("common.pending") }}
            </span>
          </div>
          <!-- Automatic reporting info display -->
          <div
            v-if="
              declarationsAutomationStatus !==
                DeclarationAutomationStatus.Loading &&
              declarationsAutomationStatus !==
                DeclarationAutomationStatus.Pending
            "
          >
            <div>
              {{ $t("dashboard.automaticReporting.lastUpdated") }}
              <span>{{ formatDateTime(declarationsUpdatedAt) }}</span>
            </div>
            <div>
              {{ $t("dashboard.automaticReporting.lastDeclaredMonth") }}
              <span>{{ formatDate(lastDeclarationMonth) }}</span>
            </div>
            <div>
              {{ $t("dashboard.automaticReporting.lastDeclaredAmount") }}
              <span>{{ formatLargeNumber(lastDeclarationMonthAmount) }}</span>
              {{ $t("common.mwh") }}
            </div>
          </div>
          <!-- Automatic reporting warning display -->
          <div
            v-if="
              declarationsAutomationStatus ===
              DeclarationAutomationStatus.RequiresAction
            "
            class="text-warning-emphasis bg-warning-subtle rounded p-4 my-2"
          >
            <div class="pb-2">
              {{ $t("dashboard.automaticReporting.helpNeeded") }}
            </div>
            <button class="btn btn-primary" @click="openHelpCrunch">
              {{ $t("common.contactSupport") }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="filteredRegistryDevices.length > 0">
        <div
          v-for="registryDevice in filteredRegistryDevices"
          :key="registryDevice.id"
        >
          <div class="card rounded-0 border-start-0 border-end-0">
            <div class="card-body">
              <div>
                <h2
                  class="accordion-header"
                  :id="'heading' + registryDevice.id"
                >
                  <a
                    href="#"
                    class="collapsed d-flex flex-row align-items-center"
                    type="accordion-button button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapse' + registryDevice.id"
                    aria-expanded="false"
                    :aria-controls="'collapse' + registryDevice.id"
                  >
                    <div class="me-3 text-secondary-emphasis">
                      <TechnologyIcon :technology="registryDevice.technology" />
                    </div>
                    <div>
                      <div class="card-title mb-1 fs-7 fw-bold">
                        {{ registryDevice.device_name }}
                        <label
                          v-if="is_admin"
                          class="alert alert-subtle-warning text-center admin-extra-info p-0"
                        >
                          {{ registryDevice.id }}
                        </label>
                      </div>
                      <h6 class="text-body-tertiary">
                        <font-awesome-icon icon="square-bolt" />
                        {{ registryDevice.device_power }} kW
                        <font-awesome-icon icon="hashtag" class="ms-1" />
                        {{ registryDevice.device_eic }}
                      </h6>
                    </div>
                  </a>
                </h2>
                <!-- Automated declarations subsection -->
                <div
                  class="mb-1 mt-1"
                  v-if="company.country === 'LT' && reportingEnabled"
                >
                  <div
                    class="input-group input-group-sm border rounded d-flex d-lg-inline-flex flex-column flex-lg-row"
                  >
                    <!--No report to display-->
                    <template
                      v-if="
                        !declarationsMap.get(registryDevice.id) ||
                        !esoMappings.get(registryDevice.device_eic)
                      "
                    >
                      <span
                        v-if="!esoMappings.get(registryDevice.device_eic)"
                        class="input-group-text bg-warning-subtle text-warning-emphasis"
                      >
                        <FontAwesomeIcon
                          icon="link-horizontal-slash"
                          class="me-1"
                        />
                        {{
                          $t("dashboard.automaticReporting.missingAssociation")
                        }}
                      </span>
                      <span
                        v-else
                        class="input-group-text bg-warning-subtle text-warning-emphasis"
                      >
                        <FontAwesomeIcon icon="check" class="me-1" />
                        {{
                          $t(
                            "dashboard.automaticReporting.automaticallyDeclared"
                          )
                        }}
                      </span>
                    </template>
                    <!-- Declaration display -->
                    <template v-else>
                      <span class="input-group-text">
                        <FontAwesomeIcon
                          icon="check"
                          class="me-1 text-success-emphasis"
                        />
                        {{
                          $t(
                            "dashboard.automaticReporting.automaticallyDeclared"
                          )
                        }}
                      </span>
                      <span class="input-group-text">
                        {{ $t("dashboard.automaticReporting.declaredFor") }}
                        {{
                          formatDate(
                            declarationsMap.get(registryDevice.id)
                              ?.production_month
                          )
                        }}
                      </span>
                      <span class="input-group-text">
                        {{ $t("dashboard.automaticReporting.amount") }}
                        {{
                          formatLargeNumber(
                            declarationsMap.get(registryDevice.id)
                              ?.production_amount
                          )
                        }}
                        {{ $t("common.mwh") }}
                      </span>
                      <span
                        v-if="
                          declarationsMap.get(registryDevice.id)?.status ===
                          DeclarationStatus.PendingApproval
                        "
                        class="input-group-text text-warning"
                      >
                        <FontAwesomeIcon icon="clock" class="me-1" />
                        {{ $t("dashboard.automaticReporting.pendingApproval") }}
                      </span>
                      <span
                        v-else-if="
                          declarationsMap.get(registryDevice.id)?.status ===
                          DeclarationStatus.Rejected
                        "
                        class="input-group-text text-danger-emphasis"
                      >
                        <FontAwesomeIcon icon="warning" class="me-1" />
                        {{ $t("common.rejected") }}
                      </span>
                    </template>
                  </div>
                </div>
                <div
                  :id="'collapse' + registryDevice.id"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'heading' + registryDevice.id"
                >
                  <div class="card-text">
                    <div class="accordion border rounded p-2">
                      <div class="accordion-item">
                        <div class="accordion-body">
                          <div class="registryDevice-segment">
                            <div class="registryDevice-property">
                              {{ $t("registry_device.device_code") }}:
                            </div>
                            <div class="registryDevice-value">
                              {{ registryDevice.device_eic }}
                            </div>
                          </div>
                          <div class="registryDevice-segment">
                            <div class="registryDevice-property">
                              {{ $t("registry_device.device_power") }}:
                            </div>
                            <div class="registryDevice-value">
                              {{ registryDevice.device_power }}
                            </div>
                          </div>
                          <div class="registryDevice-segment">
                            <div class="registryDevice-property">
                              {{ $t("registry_device.technology_code") }}:
                            </div>
                            <div class="registryDevice-value">
                              {{ registryDevice.technology_code }}
                            </div>
                          </div>
                          <div class="registryDevice-segment">
                            <div class="registryDevice-property">
                              {{ $t("registry_device.supported_device") }}?
                            </div>
                            <div class="registryDevice-value">
                              {{
                                registryDevice.support
                                  ? $t("common.yes")
                                  : $t("common.no")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SlottedContainerComponent>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  inject,
  computed,
} from "vue";
import type { RegistryDevice, Company } from "@/types";
import TechnologyIcon from "@/components/common/TechnologyIcon.vue";
import SlottedContainerComponent from "@/components/common/SlottedContainerComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useHelpCrunch } from "@/composables/useHelpCrunch";
import {
  formatDateTime,
  formatDate,
  formatLargeNumber,
} from "@/composables/formatUtils";

type ProductionDeclaration = {
  updated_at: string;
  production_month: string;
  production_amount: number;
  registry_device_id: number;
  status: string;
};

enum DeclarationAutomationStatus {
  Loading = "loading",
  Active = "active",
  Pending = "pending",
  RequiresAction = "requires_action",
}

enum DeclarationStatus {
  PendingApproval = "PENDING_APPROVAL",
  Issued = "ISSUED",
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

export default defineComponent({
  name: "RegistryDevicesSection",
  components: {
    TechnologyIcon,
    SlottedContainerComponent,
    FontAwesomeIcon,
  },
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
    registryDevices: {
      type: Array as PropType<RegistryDevice[]>,
      required: true,
    },
  },
  setup(props) {
    const reportingEnabled = ref<boolean>(true);

    const isLoading = ref<boolean>(true);
    const esoMappings = ref<Map<string, boolean>>(new Map());
    const declarationsMap = ref<Map<number, ProductionDeclaration>>(new Map());
    const { openHelpCrunch } = useHelpCrunch();
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;

    const filteredRegistryDevices = computed(() => {
      if (!props.company.registry_excluded_devices) {
        return props.registryDevices;
      }

      let blacklist = props.company.registry_excluded_devices.map((str) =>
        str.trim()
      );

      return props.registryDevices.filter(
        (device) => !blacklist.includes(device.device_eic)
      );
    });

    const declarationsAutomationStatus = computed(() => {
      // Only calculate status for Lithuanian companies
      if (props.company.country !== "LT") {
        return null;
      }

      // Don't show any status while loading
      if (isLoading.value) {
        return DeclarationAutomationStatus.Loading;
      }

      // Once loaded, determine the actual status
      if (declarationsMap.value.size > 0 && esoMappings.value.size > 0) {
        if (
          declarationsMap.value.size === filteredRegistryDevices.value.length
        ) {
          return DeclarationAutomationStatus.Active;
        } else {
          return DeclarationAutomationStatus.RequiresAction;
        }
      }
      return DeclarationAutomationStatus.Pending;
    });

    const declarationsUpdatedAt = computed(() => {
      if (declarationsMap.value.size > 0) {
        const lastDeclaration = Array.from(declarationsMap.value.values()).sort(
          (a, b) => a.updated_at.localeCompare(b.updated_at)
        )[0];
        return lastDeclaration.updated_at;
      }
      return null;
    });

    const lastDeclarationMonth = computed(() => {
      if (declarationsMap.value.size > 0) {
        const lastDeclaration = Array.from(declarationsMap.value.values()).sort(
          (a, b) => a.updated_at.localeCompare(b.updated_at)
        )[0];
        return lastDeclaration.production_month;
      }
      return null;
    });

    const lastDeclarationMonthAmount = computed(() => {
      // Sum all amounts declared in the last month declared
      if (lastDeclarationMonth.value) {
        return Array.from(declarationsMap.value.values()).reduce(
          (acc, declaration) => {
            if (declaration.production_month === lastDeclarationMonth.value) {
              return acc + declaration.production_amount;
            }
            return acc;
          },
          0
        );
      }
      return 0;
    });

    function processDeclarationsToMap(
      productionDeclarations: ProductionDeclaration[]
    ) {
      const newDeclarationsMap = new Map<number, ProductionDeclaration>();
      productionDeclarations.forEach((declaration) => {
        if (newDeclarationsMap.has(declaration.registry_device_id)) {
          const competingDeclaration = newDeclarationsMap.get(
            declaration.registry_device_id
          );
          if (
            competingDeclaration &&
            competingDeclaration.production_month < declaration.production_month
          ) {
            newDeclarationsMap.set(declaration.registry_device_id, declaration);
          }
        } else {
          newDeclarationsMap.set(declaration.registry_device_id, declaration);
        }
      });
      return newDeclarationsMap;
    }

    const fetchProductionDeclarations = async () => {
      // Only fetch for Lithuanian companies
      if (props.company.country !== "LT" || !reportingEnabled.value) {
        isLoading.value = false;
        return;
      }

      try {
        const responseReq = await goSolidApi.get<ProductionDeclaration[]>(
          `/api/grexel/production-declarations/${props.company.id}`
        );
        const responseMappingReq = await goSolidApi.get<any>(
          `/api/integration/grexel/get-eso-mappings/${props.company.id}`
        );

        const [response, responseMapping] = await Promise.all([
          responseReq,
          responseMappingReq,
        ]);

        for (const d of responseMapping.data) {
          esoMappings.value.set(d.grid_reference, d.active);
        }

        declarationsMap.value = processDeclarationsToMap(response.data);
      } catch (error) {
        globalErrorHandler(error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchProductionDeclarations();
    });
    return {
      isLoading,
      openHelpCrunch,
      declarationsMap,
      declarationsAutomationStatus,
      declarationsUpdatedAt,
      lastDeclarationMonth,
      lastDeclarationMonthAmount,
      DeclarationAutomationStatus,
      DeclarationStatus,
      filteredRegistryDevices,
      formatDateTime,
      esoMappings,
      formatDate,
      formatLargeNumber,
      reportingEnabled,
    };
  },
});
</script>

<style scoped>
.input-group-sm {
  font-size: 0.875rem;
  width: auto;
  overflow: hidden;
  > .input-group-text {
    padding: 0.1rem 0.4rem;
    background-color: rgba(0, 0, 0, 0) !important;
    border-top: 0;
    border-bottom: 0;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
    &.bg-warning-subtle {
      background-color: var(--phoenix-warning-bg-subtle) !important;
    }
  }
}
</style>
