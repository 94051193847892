<!-- client/src/components/signup/ee/GridContractUploadComponent.vue -->

<template>
  <div class="grid-contract-upload p-4 card">
    <h3 class="mb-3">{{ $t("signup.grid_contract_upload.title") }}</h3>
    <p class="mb-4 mb-sm-5">
      {{ $t("signup.grid_contract_upload.description") }}
      <a
        class="link fw-bold"
        role="button"
        @click="addAuthorizationAgreement()"
      >
        <font-awesome-icon icon="right-to-bracket" />
        {{ $t("signup.grid_contract_upload.link_text") }}
      </a>
    </p>
    <div
      class="dropzone dz-clickable d-flex justify-content-center align-items-center px-4 mb-2"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
      @click="triggerFileInput"
      :class="{ dragging: isDragging }"
    >
      <input
        class="form-control visually-hidden"
        type="file"
        @change="handleFileUpload"
        ref="fileInput"
      />
      <div class="dropzone-content">
        <div class="d-flex align-items-center">
          <div class="upload-icon">
            <font-awesome-icon icon="cloud-upload" />
          </div>
          <div class="text-start">
            <p>{{ $t("signup.grid_contract_upload.upload_prompt") }}</p>
            <small>{{
              $t("signup.grid_contract_upload.supported_formats")
            }}</small>
          </div>
        </div>
      </div>
    </div>
    <div
      class="invalid-feedback mb-2 d-flex justify-content-center align-items-center"
      v-for="error of v$.hasGenerator.$errors"
      :key="error.$uid"
    >
      <strong>{{ error.$message }}</strong>
    </div>
    <div
      class="invalid-feedback mb-2 d-flex justify-content-center align-items-center"
      v-if="existingGridContractEIC"
    >
      <strong>
        {{
          $t("signup.grid_contract_upload.existing_contract_error", {
            eic: existingGridContractEIC,
          })
        }}
        <a :href="`/dashboard`">{{
          $t("signup.grid_contract_upload.manage_devices_link")
        }}</a>
      </strong>
    </div>
    <div
      class="invalid-feedback mb-2 d-flex justify-content-center align-items-center"
      v-if="wrongFileError"
    >
      <div>
        <strong>
          {{ $t("signup.grid_contract_upload.wrong_file_error") }}
        </strong>
        <div>
          {{ $t("signup.grid_contract_upload.contact_support") }}
        </div>
      </div>
    </div>
    <BasicInfoCardComponent
      :buttonText="$t('common.read_more_button')"
      :titleText="$t('signup.grid_contract_upload.finding_contract_title')"
      @setForceToggle="setForceToggle"
    >
      <p class="mt-2 mb-1">
        <b>{{ $t("signup.grid_contract_upload.elektrilevi_contract_info") }}</b>
        <br />
        <a
          target="_blank"
          href="https://www.elektrilevi.ee/et/eteenindus/vorgulepingud"
        >
          {{ $t("signup.grid_contract_upload.elektrilevi_contract_link_text") }}
        </a>
      </p>
      <p class="mt-2 mb-1">
        <b>{{ $t("signup.grid_contract_upload.vkg_contract_info") }}</b>
        <br />
        <a target="_blank" href="https://eteenused.vkgev.ee/Login">
          {{ $t("signup.grid_contract_upload.vkg_contract_link_text") }}
        </a>
      </p>
    </BasicInfoCardComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, ref, watch } from "vue";
import type { Account } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useValidators } from "@/composables/useValidators";
import useVuelidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import BasicInfoCardComponent from "@/components/common/BasicInfoCardComponent.vue";

export default defineComponent({
  name: "GridContractUploadComponent",
  props: {
    accountData: {
      type: Object as PropType<Account>,
      required: true,
    },
    hasGenerator: {
      type: Boolean,
      required: true,
    },
    setAddAuthorizationAgreement: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    },
    setLoading: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    },
  },
  components: {
    BasicInfoCardComponent,
  },
  emits: ["uploadComplete", "errorOccured"],
  setup(props, { emit }) {
    const isDragging = ref(false);
    const existingGridContractEIC = ref<number | null>(null);
    const wrongFileError = ref(false);
    const fileInput = ref<HTMLInputElement | null>(null);
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;

    const forceToggleContractHowto = ref();
    const setForceToggle = (method: (value: boolean) => void) => {
      forceToggleContractHowto.value = method;
    };
    watch(wrongFileError, (newValue) => {
      if (newValue) {
        forceToggleContractHowto.value(true); // Automatically expand the collapse when there's a wrong file error
      }
    });

    const processFile = async (file: File) => {
      // Logically this should never happen, its here for typescript reasons
      if (!props.accountData.id) return;
      props.setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("accountId", props.accountData.id.toString());

      try {
        const response = await goSolidApi.post(
          "/api/onboarding/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200 && response.data) {
          emit("uploadComplete", response.data);
        } else {
          throw new Error("Error uploading the document");
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message !== undefined &&
          error.response.data.message.includes("Generator already registered.")
        ) {
          existingGridContractEIC.value =
            error.response.data.message.split(" - ")[1];
        } else if (
          error?.response?.data?.error ===
          "Uploaded file is not a valid grid contract"
        ) {
          wrongFileError.value = true;
        } else {
          globalErrorHandler(error);
        }
      } finally {
        props.setLoading(false);
      }
    };

    const handleFileUpload = (event: Event) => {
      const input = event.target as HTMLInputElement;
      const file = input.files?.[0];
      if (file) processFile(file);
    };

    const dragOver = () => (isDragging.value = true);
    const dragLeave = () => (isDragging.value = false);

    const handleDrop = (event: DragEvent) => {
      const file = event.dataTransfer?.files[0];
      if (file) processFile(file);
      isDragging.value = false;
    };

    const triggerFileInput = () => fileInput.value?.click();

    const addAuthorizationAgreement = () => {
      props.setAddAuthorizationAgreement(true);
    };

    // Form validation for hasGenerator
    const { t } = useI18n();
    const hasGenerator = ref(props.hasGenerator);
    const { generatorExists } = useValidators(t);
    const v$ = useVuelidate(
      { hasGenerator: { generatorExists } },
      { hasGenerator }
    );

    return {
      addAuthorizationAgreement,
      handleFileUpload,
      isDragging,
      dragOver,
      dragLeave,
      handleDrop,
      triggerFileInput,
      fileInput,
      existingGridContractEIC,
      wrongFileError,
      setForceToggle,
      v$,
    };
  },
});
</script>

<style scoped lang="scss">
.grid-contract-upload {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;

    .spinner-border {
      width: 3rem;
      height: 3rem;
    }
  }

  .dropzone {
    border: 3px dashed var(--phoenix-secondary-text-emphasis);
    border-radius: 10px;
    height: 150px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--phoenix-text-color);
    transition-duration: 200ms;
    transition-property: background-color;
    transition-timing-function: ease-out;

    &.dragging {
      background-color: #e9ecef;
    }

    &:hover {
      background-color: var(--phoenix-primary-bg-subtle);
      color: var(--phoenix-primary-text-emphasis);
      transition-duration: 200ms;
      transition-property: background-color;
      transition-timing-function: ease-out;
    }

    .dropzone-content {
      text-align: center;

      .upload-icon {
        font-size: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 1rem;
      }

      p {
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0;
      }

      small {
        display: block;
        font-size: 0.8rem;
        margin-top: 2px;
      }
    }
  }
}
</style>
