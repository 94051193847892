<template>
  <div id="AddForwardView" class="container">
    <div class="row justify-content-between align-items-center mb-3 mt-4">
      <div class="col-auto">
        <h1>{{ header_text }}</h1>
      </div>
    </div>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input
          type="text"
          class="form-control"
          id="name"
          v-model="forward.name"
          required
        />
      </div>
      <div class="mb-3">
        <label for="buyer_name" class="form-label">Buyer Name</label>
        <input
          type="text"
          class="form-control"
          id="buyer_name"
          v-model="forward.buyer_name"
          required
        />
      </div>
      <div class="mb-3">
        <label for="count" class="form-label">Units</label>
        <input
          type="number"
          class="form-control"
          id="count"
          v-model="forward.count"
          required
        />
      </div>
      <div class="mb-3">
        <label for="unit_price" class="form-label">Price</label>
        <input
          type="number"
          step="0.001"
          class="form-control"
          id="unit_price"
          v-model="forward.unit_price"
          required
        />
      </div>
      <div class="mb-3">
        <label for="comments" class="form-label">Comments</label>
        <textarea
          class="form-control"
          id="comments"
          v-model="forward.comments"
        ></textarea>
      </div>
      <div class="mb-3">
        <label for="delivery-start" class="form-label">Delivery Start</label>
        <input
          type="date"
          class="form-control"
          id="delivery-start"
          v-model="forward.delivery_start"
          required
        />
      </div>
      <div class="mb-3">
        <label for="delivery-end" class="form-label">Delivery End</label>
        <input
          type="date"
          class="form-control"
          id="delivery-end"
          v-model="forward.delivery_end"
          required
        />
      </div>
      <div class="mb-3">
        <label for="production-start" class="form-label">
          Production Start
        </label>
        <input
          type="date"
          class="form-control"
          id="production-start"
          v-model="forward.production_start"
          required
        />
      </div>
      <div class="mb-3">
        <label for="production-end" class="form-label">Production End</label>
        <input
          type="date"
          class="form-control"
          id="production-end"
          v-model="forward.production_end"
          required
        />
      </div>
      <input type="hidden" v-model="forward.id" />
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref, onMounted } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import type { Forward } from "@/types";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "AddForwardView",

  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const forward = ref<Forward>({
      id: 0,
      name: "",
      buyer_name: "",
      count: 0,
      unit_price: 0,
      comments: "",
      buyer_company_id: 0,
      delivery_start: "",
      delivery_end: "",
      production_start: "",
      production_end: "",
    });

    const router = useRouter();
    const route = useRoute();
    const forward_id = route.params.forward_id;
    const header_text =
      forward_id === "-1" ? "Add New Forward" : "Edit Forward";

    const loadForward = async (id: number) => {
      try {
        const response = await goSolidApi.get(`/api/admin/forward/${id}`);
        if (response.status === 200) {
          forward.value = response.data;
        } else {
          throw new Error("Error fetching forward data.");
        }
      } catch (error: any) {
        globalErrorHandler(error.response?.data || error);
      }
    };

    // [NEW] Check if we're editing an existing forward
    onMounted(async () => {
      if (forward_id && forward_id !== "-1") {
        await loadForward(Number(forward_id));
      }
    });

    const submitForm = async () => {
      try {
        const response = await goSolidApi.post(
          "/api/admin/forward",
          forward.value
        );
        if (response.status === 201) {
          router.back();
        } else {
          throw new Error("Error adding forward.");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    };

    return {
      forward,
      submitForm,
      header_text,
    };
  },
});
</script>

<style scoped>
#AddForwardView {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}
</style>
