import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09369e0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card document-card border-start-0 border-end-0 border-top-0 rounded-0" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "d-flex justify-content-between align-items-center mb-2" }
const _hoisted_4 = { class: "card-title mb-0" }
const _hoisted_5 = { class: "card-text text-muted small" }
const _hoisted_6 = { class: "d-flex flex-wrap gap-2 mt-3" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "mt-3" }
const _hoisted_9 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_10 = { class: "list-unstyled" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "mt-3 alert alert-subtle-warning text-center admin-extra-info"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "d-flex flex-wrap gap-2 justify-content-center" }
const _hoisted_15 = { class: "mb-0 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_GeneratorCardComponent = _resolveComponent("GeneratorCardComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.getDocumentTitle()), 1),
        _createElementVNode("span", {
          class: _normalizeClass([
            'badge',
            _ctx.document.status === 'completed'
              ? 'bg-success-subtle text-success-emphasis'
              : 'bg-warning-subtle text-warning-emphasis',
          ])
        }, _toDisplayString(_ctx.document.status === "completed"
              ? _ctx.$t("document.signed")
              : _ctx.$t("document.awaiting_signature")), 3)
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("dashboard.documents.last_updated")) + ": " + _toDisplayString(_ctx.document.updated_at), 1),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.document.download_url)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadDocument(_ctx.document.dokobit_token))),
              class: "btn btn-outline-primary btn-sm",
              target: "_blank"
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "download",
                class: "me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("document.download")), 1)
            ]))
          : _createCommentVNode("", true),
        (
            _ctx.document.document_signers.length > 0 &&
            _ctx.document.status !== 'completed' &&
            _ctx.document.document_signers[0].redirect_url
          )
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: _ctx.document.document_signers[0].redirect_url,
              class: "btn btn-primary btn-sm",
              target: "_blank"
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "signature",
                class: "me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("document.sign_document")), 1)
            ], 8, _hoisted_7))
          : _createCommentVNode("", true),
        (_ctx.document.status !== 'completed')
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "btn btn-outline-danger btn-sm",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteDocument(_ctx.document.dokobit_token)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "trash",
                class: "me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("document.delete_document")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          class: "btn btn-link btn-sm p-0",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleContractDetails && _ctx.toggleContractDetails(...args)))
        }, _toDisplayString(_ctx.showContractDetails
              ? _ctx.$t("dashboard.documents.hide_details")
              : _ctx.$t("dashboard.documents.show_details")), 1),
        (_ctx.showContractDetails)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("ul", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document.contract_keys, (key) => {
                  return (_openBlock(), _createElementBlock("li", { key: key }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.getDocumentInfo(key, "contract_title")) + ":", 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.getDocumentInfo(key, "contract_description")), 1)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.generator)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "btn btn-link btn-sm p-0",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleAssociatedDevice && _ctx.toggleAssociatedDevice(...args)))
            }, _toDisplayString(_ctx.showAssociatedDevice
              ? _ctx.$t("dashboard.documents.hide_associated_device")
              : _ctx.$t("dashboard.documents.show_associated_device")), 1),
            (_ctx.showAssociatedDevice)
              ? (_openBlock(), _createBlock(_component_GeneratorCardComponent, {
                  key: _ctx.generator.id,
                  generator: _ctx.generator,
                  account_id: _ctx.account_id,
                  title: 
            _ctx.$t('dashboard.companyData.device_contract', {
              deviceName: _ctx.generator.name,
            })
          ,
                  generatorDocuments: [],
                  is_admin: _ctx.is_admin
                }, null, 8, ["generator", "account_id", "title", "is_admin"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.is_admin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("button", {
              class: "btn btn-link btn-sm p-0",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleAdminSection && _ctx.toggleAdminSection(...args)))
            }, _toDisplayString(_ctx.showAdminSection
              ? _ctx.$t("common.hide_admin_options")
              : _ctx.$t("common.show_admin_options")), 1),
            (_ctx.showAdminSection)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("button", {
                      class: "btn btn-outline-warning btn-sm",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.sendEleringEmail()))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "envelope",
                        class: "me-1"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("document.resend_email")), 1)
                    ]),
                    _createElementVNode("button", {
                      class: "btn btn-outline-danger btn-sm",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.adminDeleteDocument(_ctx.document.dokobit_token)))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "trash",
                        class: "me-1"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("document.admin_delete_document")), 1)
                    ])
                  ]),
                  _createElementVNode("p", _hoisted_15, "Document ID: " + _toDisplayString(_ctx.document.id), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}