<!-- client/src/views/admin/ForwardsView.vue -->

<template>
  <div class="d-flex flex-column h-100 overflow-hidden">
    <div class="flex-grow-1 overflow-auto">
      <div class="container">
        <div class="row justify-content-between align-items-center mb-3 mt-4">
          <div class="col-auto">
            <h1>Forwards</h1>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary" @click="navigateToAddForward">
              Add New Forward
            </button>
          </div>
        </div>
        <div class="rounded border overflow-hidden">
          <div class="table-responsive">
            <table class="table table-hover align-middle mb-0">
              <thead>
                <tr>
                  <th class="ps-3 py-2">Name</th>
                  <th class="py-2">Delivery</th>
                  <th class="py-2">Production Period</th>
                  <th class="py-2">Buyer Name</th>
                  <th class="py-2">Units</th>
                  <th class="py-2">Reserved</th>
                  <th class="py-2">Price</th>
                  <th class="py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="forward in forwardsWithPeriods" :key="forward.id">
                  <td class="ps-3 py-2">{{ forward.name }}</td>
                  <td class="py-2">
                    <span class="text-body-quaternary">
                      {{ forward.delivery_start }}
                    </span>
                    <br />
                    {{ forward.delivery_end }}
                  </td>
                  <td class="py-2">
                    <span
                      v-if="forward.productionPeriod.isQuarter"
                      class="badge badge-phoenix badge-phoenix-secondary fs-8"
                      :class="forward.productionPeriod.colorClass"
                    >
                      {{ forward.productionPeriod.text }}
                    </span>
                    <template v-else>
                      {{ forward.production_start }} <br />
                      {{ forward.production_end }}
                    </template>
                  </td>

                  <td class="py-2">{{ forward.buyer_name }}</td>
                  <td class="py-2">{{ forward.count }}</td>
                  <td class="py-2">{{ forward.reserved_sum }}</td>
                  <td class="py-2">{{ forward.unit_price }}</td>
                  <td class="py-2">
                    <button
                      class="btn btn-primary btn-sm me-2"
                      @click="editForward(forward.id)"
                    >
                      Edit
                    </button>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="editForwardReservations(forward.id)"
                    >
                      Manage Reservations
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref, computed } from "vue";
import type { Forward } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "AdminForwardsView",

  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const rawForwards = ref<Forward[]>([]);
    const router = useRouter();

    const navigateToAddForward = () => {
      router.push({ name: "AdminForwardsEdit", params: { forward_id: -1 } });
    };
    const editForward = (id: number | undefined) => {
      router.push({ name: "AdminForwardsEdit", params: { forward_id: id } });
    };
    const editForwardReservations = (id: number | undefined) => {
      router.push({
        name: "AdminForwardReservationsView",
        params: { forward_id: id },
      });
    };

    // Helper function to check if date is start of quarter
    const isQuarterStart = (date: string): boolean => {
      const d = new Date(date);
      const month = d.getMonth();
      const day = d.getDate();
      return day === 1 && [0, 3, 6, 9].includes(month);
    };

    // Helper function to check if date is end of quarter
    const isQuarterEnd = (date: string): boolean => {
      const d = new Date(date);
      const month = d.getMonth();
      const day = d.getDate();
      const lastDayOfMonth = new Date(d.getFullYear(), month + 1, 0).getDate();
      return day === lastDayOfMonth && [2, 5, 8, 11].includes(month);
    };

    // Helper function to get quarter number (1-4)
    const getQuarter = (date: string): number => {
      const month = new Date(date).getMonth();
      return Math.floor(month / 3) + 1;
    };

    // Function to get quarter color class
    const getQuarterColorClass = (quarter: number): string => {
      switch (quarter) {
        case 1:
          return "border-info";
        case 2:
          return "border-warning";
        case 3:
          return "border-success";
        case 4:
          return "border-danger";
        default:
          return "border-secondary";
      }
    };

    // Function to format production period
    const formatProductionPeriod = (start: string, end: string) => {
      if (isQuarterStart(start) && isQuarterEnd(end)) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const quarter = getQuarter(start);

        // Check if dates are in the same quarter and year
        if (
          startDate.getFullYear() === endDate.getFullYear() &&
          getQuarter(start) === getQuarter(end)
        ) {
          return {
            isQuarter: true,
            text: `Q${quarter} ${startDate.getFullYear()}`,
            colorClass: getQuarterColorClass(quarter),
          };
        }
      }

      return {
        isQuarter: false,
        start,
        end,
      };
    };

    const forwardsWithPeriods = computed(() =>
      [...rawForwards.value]
        .sort((a, b) => {
          const endComparison =
            new Date(a.delivery_end).getTime() -
            new Date(b.delivery_end).getTime();
          if (endComparison !== 0) return endComparison;
          return (
            new Date(a.delivery_start).getTime() -
            new Date(b.delivery_start).getTime()
          );
        })
        .map((forward) => ({
          ...forward,
          productionPeriod: formatProductionPeriod(
            forward.production_start,
            forward.production_end
          ),
        }))
    );

    onMounted(async () => {
      try {
        const response = await goSolidApi.get("/api/admin/forward");
        if (response.status === 200 && response.data) {
          rawForwards.value = response.data;
        } else {
          throw new Error("Error fetching forwards.");
        }
      } catch (error: unknown) {
        globalErrorHandler(error);
      }
    });

    return {
      forwardsWithPeriods,
      navigateToAddForward,
      editForward,
      editForwardReservations,
    };
  },
});
</script>
